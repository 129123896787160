/**
 * @file cancellationPolicy.graphql.ts
 * queries/mutations for CancellationPolicy.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_CANCELLATION_POLICIES_QUERY = gql`
  query CancellationPolicies {
    cancellationPolicies {
      id
      createdAt
      updatedAt
      variant
      refund100 {
        periodName
        periodValue
        active
      }
      refund50 {
        periodName
        periodValue
        active
      }
      refund25 {
        periodName
        periodValue
        active
      }
      vehicleIds
    }
  }
`;

export const LOAD_CANCELLATION_POLICY_QUERY = gql`
  query CancellationPolicies($id: ID!) {
    node(id: $id) {
      id
      ... on CancellationPolicy {
        id
        createdAt
        updatedAt
        variant
        refund100 {
          periodName
          periodValue
          active
        }
        refund50 {
          periodName
          periodValue
          active
        }
        refund25 {
          periodName
          periodValue
          active
        }
        vehicleIds
      }
    }
  }
`;

export const CREATE_CANCELLATION_POLICY_MUTATION = gql`
  mutation CreateCancellationPolicy($input: CreateCancellationPolicyInput!) {
    createCancellationPolicy(input: $input) {
      cancellationPolicy {
        id
        variant
        refund100 {
          periodName
          periodValue
          active
        }
        refund50 {
          periodName
          periodValue
          active
        }
        refund25 {
          periodName
          periodValue
          active
        }
      }
    }
  }
`;

export const UPDATE_CANCELLATION_POLICY_MUTATION = gql`
  mutation UpdateCancellationPolicy($input: UpdateCancellationPolicyInput!) {
    updateCancellationPolicy(input: $input) {
      cancellationPolicy {
        id
        variant
        refund100 {
          periodName
          periodValue
          active
        }
        refund50 {
          periodName
          periodValue
          active
        }
        refund25 {
          periodName
          periodValue
          active
        }
      }
    }
  }
`;

export const REMOVE_CANCELLATION_POLICY_MUTATION = gql`
  mutation RemoveCancellationPolicy($input: RemoveCancellationPolicyInput!) {
    removeCancellationPolicy(input: $input) {
      cancellationPolicy {
        id
        variant
        refund100 {
          periodName
          periodValue
          active
        }
        refund50 {
          periodName
          periodValue
          active
        }
        refund25 {
          periodName
          periodValue
          active
        }
      }
    }
  }
`;
