/**
 * @file useAuth/Provider.tsx
 * ContextProvider for Authentication
 */
import React, { useEffect, useState, useCallback } from "react";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";

import { LOAD_ME_QUERY } from "../../graphql";
import AuthContext from "./Context";
import authApi from "./authApi";
import { useSnackbar } from "../useSnackbar";
import { auth } from "../../utils/firebaseApp";
import { onIdTokenChanged, signOut } from "firebase/auth";
import { useSegment } from "../useSegment";
import { useResendInvite } from "../useResendInvite";
import { moovsBlue } from "design-system/colors";
import { useAnalytics } from "..";

function AuthProvider({ children }) {
  // hooks
  const client = useApolloClient();
  const snackbar = useSnackbar();
  const analytics = useSegment();
  const history = useHistory();
  const { track } = useAnalytics();
  const { onResendInvite } = useResendInvite({
    onCompleted: () => track("unauthenticated_inviteEmailResent"),
  });

  // state
  const [authStage, setAuthStage] = useState<
    "pending" | "authenticated" | "rejected"
  >("pending");

  // queries
  const [loadMeQuery, { data, refetch: refetchLoadMeQuery }] = useLazyQuery(
    LOAD_ME_QUERY,
    {
      onCompleted: (data) => {
        // me query will only return data if valid cookie is found.
        if (data?.me?.id) {
          setAuthStage("authenticated");
        } else {
          handleLogout();
        }
      },
      onError: () => {
        handleLogout();
      },
      fetchPolicy: "network-only",
    }
  );

  // event handlers
  // logout / clear user cache
  const handleLogout = useCallback(async () => {
    try {
      await authApi.logout();
      await signOut(auth);
      analytics?.reset();

      // clears cache if coming from authenticated or if it has data
      if (authStage === "authenticated" || data?.me?.id) {
        client.clearStore();
      }

      setAuthStage("rejected");
    } catch (error) {
      snackbar.error("error logging out");
    }
  }, [analytics, authStage, data?.me?.id, client, snackbar]);

  // login / signup
  const handleLogin = useCallback(
    async (idToken: string) => {
      const { error } = await authApi.login({ idToken });

      if (error) {
        handleLogout();

        switch (error.code) {
          case "MOOVS_NOT_FOUND_ERROR":
            snackbar.error(
              "The email address or password you entered is incorrect. Please try again or create an account."
            );
            break;

          case "MOOVS_DEACTIVATED_ACCOUNT_LOGIN":
            history.replace("/deactivated");
            snackbar.error("Your subscription is not active");
            break;

          case "MOOVS_USER_INVITE_PENDING":
            snackbar.error(
              "Member Invite Pending, check email to complete onboarding, ",
              {
                linkLabel: "Resend Email",
                linkColor: moovsBlue,
                onLinkClick: onResendInvite(error.clientData.userId),
                inlineLink: true,
              }
            );
            break;

          default:
            snackbar.error("Error Logging In");
        }

        return false;
      }

      await refetchLoadMeQuery();
      setAuthStage("authenticated");

      return true;
    },
    [refetchLoadMeQuery, onResendInvite, snackbar, history, handleLogout]
  );

  // effects
  // mounting app
  useEffect(() => {
    setAuthStage("pending");
    loadMeQuery();
  }, [loadMeQuery]);

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      if (!user) {
        handleLogout();
      }
    });

    return unsubscribe();
  }, [handleLogout]);

  return (
    <AuthContext.Provider
      value={{
        authStage,
        onLogin: handleLogin,
        onLogout: handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
