/**
 * @file useChargePayer.ts
 *
 * - provides all handlers for fields
 * - syncs dollar and percent values whenever the input value changes
 * - shapes createRequestPaymentInput before sending mutation
 *
 */
import { ChangeEvent, useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import flatMap from "lodash/flatMap";

import { DollarPercentVariant } from "components/pricing/utils";
import { CREATE_REQUEST_PAYMENT_MUTATION } from "globals/graphql";
import { useAnalytics, useInputFields, useSnackbar } from "globals/hooks";
import {
  MutationCreateRequestPaymentArgs,
  PaymentMethod,
  PaymentMethodEnum,
  PaymentPayer,
} from "../../../types";
import { ChargePayerInputErrors, TripChargeDetails } from "./types";
import { ensureTripChargeDetailsSumToInputDollarAmt } from "./utils";

type UseChargePayerArgs = {
  payerBeingCharged: {
    payerType: PaymentPayer;
    payerId: string;
  };
  tripChargeDetailsList: TripChargeDetails[];
  amountDue: number;
  requestId: string;
  initialPaymentMethod: PaymentMethod | null;
  onClose: () => void;
};

function useChargePayer(args: UseChargePayerArgs) {
  const {
    tripChargeDetailsList: initialTripChargeDetailsList,
    initialPaymentMethod,
    payerBeingCharged: { payerType },
    amountDue,
    onClose,
    requestId,
  } = args;

  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // ref
  const dollarPercentVariant = useRef(DollarPercentVariant.Dollar);

  // state
  const [inputDollarAmt, setInputDollarAmt] = useState<number>(amountDue);
  const [inputWholePercentValue, setInputWholePercentValue] = useState<number>( // NOT decimal value -> ex: if user inputs 50%, this value is 50
    amountDue ? 100 : 0 // if there is nothing to charge, then we'll set percent to 0.
  );
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [note, setNote] = useState<string>("");
  const [method, setMethod] = useState<PaymentMethodEnum>(
    initialPaymentMethod ? PaymentMethodEnum.Card : null
  );
  const [paymentMethodId, setPaymentMethodId] = useState<string>(
    initialPaymentMethod?.id || ""
  );
  const [tripChargeDetailsList, setTripChargeDetailsList] = useState<
    TripChargeDetails[]
  >(initialTripChargeDetailsList);

  // mutations
  const [createRequestPayment] = useMutation(CREATE_REQUEST_PAYMENT_MUTATION, {
    refetchQueries: ["Request"],
    onCompleted() {
      track("reservation_chargeCreated");
      snackbar.success("Successfully created payment");
      onClose();
    },
    onError(error) {
      setSubmitDisabled(false);
      const tripId = error.graphQLErrors[0]?.extensions?.tripId;
      snackbar.error(
        error.message,
        tripId && {
          linkLabel: "View Failed Charge",
          link: `/reservations/${requestId}/update/${tripId}`,
        }
      );
    },
  });

  // invoke mutation handler
  const handleCreateRequestPayment = () => {
    setSubmitDisabled(true);
    createRequestPayment({
      variables: shapeRequestPaymentInput(),
    });
  };

  // input errors
  const {
    inputErrors,
    resetInputErrorField,
    setInputErrorField,
    errorCheckAndSubmit,
  } = useInputFields<ChargePayerInputErrors>({
    onSuccessfulSubmit: handleCreateRequestPayment,
  });

  // utils
  // create request payment input for createRequestPayment mutation
  const shapeRequestPaymentInput = (): MutationCreateRequestPaymentArgs => {
    const constituentPayments = flatMap(
      tripChargeDetailsList,
      (chargeDetails) =>
        chargeDetails.amountToCharge
          ? [
              {
                routeId: chargeDetails.routeId,
                amount: chargeDetails.amountToCharge,
              },
            ]
          : []
    );

    return {
      input: {
        requestId,
        method,
        note,
        subPayments: constituentPayments,
        paymentPayer: payerType,
        ...(paymentMethodId && { paymentMethodId }),
      },
    };
  };

  // event handlers

  // checks for errors, and if there aren't any, creates and sends composite payment
  const handleCreateCharges = () => {
    if (!method) {
      setInputErrorField("method", "Please select a payment method");
    }

    if (inputWholePercentValue > 100 || inputDollarAmt > amountDue) {
      setInputErrorField(
        "inputChargeAmt",
        "Please enter a charge less than or equal to the amount due"
      );
    }

    if (!inputDollarAmt || inputDollarAmt < 0.5) {
      setInputErrorField(
        "inputChargeAmt",
        "Please enter a value that equals a minimium of a $0.50 charge"
      );
    }

    errorCheckAndSubmit();
  };

  const handleMethodInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let paymentMethodId: string;

    let method: PaymentMethodEnum = event.target.value as PaymentMethodEnum;

    // if event.target.value is not one of enum values, then it is storing the payment method id
    if (
      !Object.values(PaymentMethodEnum).includes(method) &&
      method !== undefined
    ) {
      paymentMethodId = event.target.value;
      method = PaymentMethodEnum.Card;
    }

    resetInputErrorField("method");

    setMethod(method);
    setPaymentMethodId(paymentMethodId || "");
  };

  const handleNoteInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value;

    value.length >= 1000
      ? setInputErrorField("note", "Reached character limit")
      : resetInputErrorField("note");

    setNote(value);
  };

  const handleDollarInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newInputDollarAmt = Number(event.target.value);
    const newInputWholePercent = (newInputDollarAmt / (amountDue || 1)) * 100;

    // set state
    setInputDollarAmt(newInputDollarAmt);
    setInputWholePercentValue(newInputWholePercent);

    // reset errors
    resetInputErrorField("inputChargeAmt");

    // set charge details
    setTripChargeDetailsList((prev) =>
      ensureTripChargeDetailsSumToInputDollarAmt(prev, newInputDollarAmt)
    );
  };

  const handlePercentInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newInputWholePercent = Number(event.target.value);
    const newDecimalPercent = newInputWholePercent / 100;
    const newInputDollarAmt = newDecimalPercent * amountDue;

    // set state
    setInputWholePercentValue(newInputWholePercent);
    setInputDollarAmt(newInputDollarAmt);

    // reset errors
    resetInputErrorField("inputChargeAmt");

    // set charge details
    setTripChargeDetailsList((prev) =>
      ensureTripChargeDetailsSumToInputDollarAmt(prev, newInputDollarAmt)
    );
  };

  const handleDollarPercentVariantChange = (variant: DollarPercentVariant) => {
    dollarPercentVariant.current = variant;
  };

  return {
    inputDollarAmt,
    inputWholePercentValue,
    tripChargeDetailsList,
    note,
    inputErrors,
    submitDisabled,
    paymentMethodValue: paymentMethodId || method || "",
    onCreateCharges: handleCreateCharges,
    onMethodInputChange: handleMethodInputChange,
    onNoteInputChange: handleNoteInputChange,
    onDollarInputChange: handleDollarInputChange,
    onPercentInputChange: handlePercentInputChange,
    onDollarPercentVariantChange: handleDollarPercentVariantChange,
  };
}

export { useChargePayer };
