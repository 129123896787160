import React, { useState } from "react";

import { grayDark } from "design-system/colors";
import { useAnalytics } from "globals/hooks";
import { EllipsisMenuOption } from "components/MoovsEllipsisMenu";
import CancelTripIcon from "design-system/icons/actions/CancelTripIcon";

type UseCancelTripClickProps = {
  isFarmee: boolean;
  analyticsName?: string;
};

function useCancelTripClick(
  props: UseCancelTripClickProps
): EllipsisMenuOption & { open: boolean; onClose: () => void } {
  const { analyticsName, isFarmee } = props;

  // hooks
  const { track } = useAnalytics();

  // state
  const [cancelTripDialogOpen, setCancelTripDialogOpen] = useState(false);

  // event handlers
  const handleCancelFarmOutClick = () => {
    if (analyticsName) track(analyticsName);
    setCancelTripDialogOpen(true);
  };

  const handleCancelFarmOutDialogClose = () => {
    setCancelTripDialogOpen(false);
  };

  return {
    text: "Cancel Trip",
    icon: <CancelTripIcon color={grayDark} size="small" />,
    onClick: handleCancelFarmOutClick,
    hideOption: isFarmee,

    // dialog state
    open: cancelTripDialogOpen,
    onClose: handleCancelFarmOutDialogClose,
  };
}

export { useCancelTripClick };
