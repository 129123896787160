import React, { useState } from "react";

import { Trip } from "types";
import { AddTripIcon } from "design-system/icons";
import { grayDark } from "design-system/colors";
import { useAnalytics } from "globals/hooks";
import { EllipsisMenuOption } from "components/MoovsEllipsisMenu";

type UseDuplicateTripClickProps = {
  trip: Trip;
  analyticsName?: string;
  isFarmee?: boolean;
};

function useDuplicateTripClick(
  props: UseDuplicateTripClickProps
): EllipsisMenuOption & { open: boolean; onClose: () => void } {
  const { trip, analyticsName, isFarmee } = props;

  // hooks
  const { track } = useAnalytics();

  // state
  const [duplicateTripDialogOpen, setDuplicateTripDialogOpen] = useState(false);

  // derived state
  const isCancelledTrip = !!trip?.cancelledAt;
  const duplicateTripDisabled = isCancelledTrip;

  const handleDuplicateTripDialogOpen = () => {
    setDuplicateTripDialogOpen(true);
  };

  const handleDuplicateTripDialogClose = () => {
    setDuplicateTripDialogOpen(false);
  };

  const handleDuplicateTripClick = () => {
    if (analyticsName) track(analyticsName);

    handleDuplicateTripDialogOpen();
  };

  return {
    text: "Duplicate Trip",
    icon: <AddTripIcon color={grayDark} size="small" />,
    onClick: handleDuplicateTripClick,
    hideOption: isFarmee,
    disableOption: duplicateTripDisabled,
    tooltip: duplicateTripDisabled
      ? { titleText: "Cannot duplicate cancelled trip" }
      : undefined,

    // dialog states
    open: duplicateTripDialogOpen,
    onClose: handleDuplicateTripDialogClose,
  };
}

export { useDuplicateTripClick };
