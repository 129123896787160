import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
  ChangeEvent,
} from "react";
import { useMutation } from "@apollo/client";
import { useDebounce } from "use-debounce";
import { Draggable, Droppable } from "react-beautiful-dnd";
import moment from "moment-timezone";
import * as Sentry from "@sentry/react";

import {
  Box,
  CircularProgress,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";

import {
  LabeledInlineInput,
  LabeledInlineDateTimePicker,
  LabeledInlineLocationAutocomplete,
  LabeledInlineAirportAutocomplete,
  LabeledInlineAirlineAutocomplete,
  LabeledInlineCharacterLimitedTextInput,
} from "../../../design-system/components/inputs";
import RemoveDialog from "../../RemoveDialog";
import FlightInfoCard from "../../flights/components/FlightInfoCard/FlightInfoCard";
import {
  usePrevious,
  useScreenSize,
  useSnackbar,
} from "../../../globals/hooks";
import { applyUTCOffsetToTime } from "../../../globals/utils/helpers";
import {
  Stop,
  UpdateStopInput,
  TrackedFlight,
  TripCategory,
} from "../../../types";
import {
  UPDATE_STOP_MUTATION,
  REMOVE_STOP_MUTATION,
  UPDATE_TRACKED_FLIGHT_MUTATION,
} from "../../../globals/graphql";
import AddressAirportToggle from "../../globals/AddressAirportToggle";
import MoovsTooltip from "../../../components/MoovsTooltip";
import { RearrangeIcon, WarningIcon } from "../../../design-system/icons";
import {
  alabaster,
  white,
  orange,
  grayLight,
  errorRed,
  black,
} from "../../../design-system/colors";
import AdditionalStopInfoButtonPanel from "../AdditionalStopInfoButtonPanel";
import EditRemoveStopButtons from "../EditRemoveStopButtons";
import AdditionalStopInfoDialog from "../AdditionalStopInfoDialog";
import { LabeledInlineFlightNumberInput } from "../../../design-system/components/inputs/inline/LabeledInlineInputs";
import MultipleFlightsDialog from "../create/MultipleFlightsDialog";
import { usePotentialTrackedFlight } from "../hooks/usePotentialTrackedFlightQuery";

type TripInfoUpdateBlockItemProps = {
  stop: Stop;
  firstStop: Stop;
  stopsLength: number;
  setSaveIndicatorState: Function;
  suggestedAddressInfo?: {
    firstName: string;
    lastName: string;
    address: string;
    mode: string;
  }[];
  canRemoveStop: boolean;
  showIncorrectStopOrderWarning: boolean;
  showMissingDropOffDateTimeWarning: boolean;
  tripCategory: TripCategory;

  // draggable props
  nextStop: any;
  isSourceIndex: boolean;
  isDestinationIndex: boolean;
};

function TripInfoUpdateBlockItem(props: TripInfoUpdateBlockItemProps) {
  const {
    stop,
    firstStop,
    stopsLength,
    setSaveIndicatorState,
    suggestedAddressInfo,
    canRemoveStop,
    showIncorrectStopOrderWarning,
    showMissingDropOffDateTimeWarning,
    tripCategory,
    // draggable props
    nextStop,
    isSourceIndex,
    isDestinationIndex,
  } = props;

  const snackbar = useSnackbar();
  const { isMobileView } = useScreenSize();

  // derived state
  const prevFirstStopDateTime = usePrevious(firstStop.dateTime);
  const isShuttleReturn = tripCategory === TripCategory.ShuttleReturn;
  const isShuttleReservation =
    tripCategory === TripCategory.ShuttlePickUp || isShuttleReturn;
  const isShuttlePickUpOrDropOffStop =
    isShuttleReservation &&
    (stop.stopIndex === 1 || stop.stopIndex === stopsLength);

  // state
  const [additionalStopInfoOpen, setAdditionalStopInfoOpen] = useState(false);
  const [variant, setVariant] = useState(stop.variant);
  const [variantDialogOpen, setVariantDialogOpen] = useState(false);
  const [variantDialogType, setVariantDialogType] = useState<
    "address" | "airport"
  >(null);

  const [stopState, setStopState] = useState<Partial<Stop>>();

  const [flightNumberInput, setFlightNumberInput] = useState(
    // remove airlineIcao from flightNumber
    stop?.flightNumber?.replace(/\D/g, "")
  );
  const [refreshingFlight, setRefreshingFlight] = useState(false);
  const [multipleFlightsDialogData, setMultipleFlightsDialogData] = useState<{
    flights: TrackedFlight[];
    flightNumber: string;
    inputAirportIcao: string;
  }>(undefined);

  const [debouncedStop] = useDebounce(stopState, 500);
  const initialLoadFlag = useRef(false);

  const airportRef = useRef(null);
  const addressRef = useRef(null);

  const stopLabel = useMemo(() => {
    const { stopIndex } = stop;

    if (stopIndex === 1) return "Pick-up";
    if (stopIndex === stopsLength) return "Drop-off";

    return `Stop ${stopIndex - 1}`;
  }, [stop, stopsLength]);

  let nextStopValue = nextStop?.location;
  let nextStopLabel = "Address";

  if (nextStop?.airport) {
    const { iataCode, airportName } = nextStop.airport;

    nextStopValue = `${iataCode} - ${airportName}`;
    nextStopLabel = "Airport";
  }

  // query
  const { loadPotentialTrackedFlight, potentialTrackedFlightLoading } =
    usePotentialTrackedFlight({
      stop: stopState,
      onStopChange: setStopState,
      setMultipleFlightsDialogData,
    });

  // mutations
  const [updateStop, { loading: updateStopLoading }] = useMutation(
    UPDATE_STOP_MUTATION,
    {
      refetchQueries: ["Trip", "OperatorRoute", "Request"],
      onCompleted() {
        setSaveIndicatorState("saved");
        if (stopState.variant === "airport") {
          handleStopFlightSearch();
        }
      },
      onError(error) {
        const errorMessage = error.message
          ? `Error: ${error.message.replace("GraphQL error:", "")}`
          : "Error updating stop";

        snackbar.error(errorMessage);
        Sentry.captureException(error);
        setSaveIndicatorState("error");
      },
      awaitRefetchQueries: true,
    }
  );

  const [removeStop] = useMutation(REMOVE_STOP_MUTATION, {
    refetchQueries: ["Trip"],
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError(error) {
      setSaveIndicatorState("error");
      Sentry.captureException(error);
      snackbar.error("Error removing stop");
    },
  });

  const [updateTrackedFlight] = useMutation(UPDATE_TRACKED_FLIGHT_MUTATION, {
    onCompleted(data) {
      const updatedTrackedFlight = data.updateTrackedFlight.trackedFlight;
      setStopState({
        ...stopState,
        trackedFlight: updatedTrackedFlight,
      });
      setRefreshingFlight(false);
    },
    onError(error) {
      setRefreshingFlight(false);
      Sentry.captureException(error);
      snackbar.error("Error updating flight");
    },
  });

  // event handlers
  const handleVariantChange = () => {
    // resets data on switching tabs
    if (variantDialogType === "address") {
      setStopState({
        ...stopState,
        flightNumber: null,
        trackedFlight: null,
        airportIcao: null,
        airline: null,
        airlineIcao: null,
        airport: null,
        location: "",
        locationAlias: "",
      });
    } else if (variantDialogType === "airport") {
      setStopState({
        ...stopState,
        location: "",
        locationAlias: "",
        airline: null,
        airlineIcao: null,
      });
      setFlightNumberInput(null);
    }

    setVariant(variantDialogType);
    setVariantDialogOpen(false);

    // focus on address / airport
    setTimeout(() => {
      if (variantDialogType === "address") addressRef.current.focus();
      if (variantDialogType === "airport") airportRef.current.focus();
    }, 0);
  };

  const handleVariantClick = (_, newType: "address" | "airport") => {
    if (newType !== null) {
      setVariantDialogType(newType);
      setVariantDialogOpen(true);
    }
  };

  // blurs input focus when clicking drag handle
  // dnd library prevents default on handle events
  const handleBlur = () => {
    addressRef?.current && addressRef.current.blur();
    airportRef?.current && airportRef.current.blur();
  };

  const handleRemoveStop = () => {
    setSaveIndicatorState("loading");

    removeStop({
      variables: {
        input: {
          id: stop.id,
        },
      },
    });
  };

  const handleAdditionalStopInfoChange = (
    additionalStopInfo: Partial<Stop>
  ) => {
    setStopState({
      ...stopState,
      ...additionalStopInfo,
      ...(additionalStopInfo.dateTime !== stop.dateTime && {
        dateTime: moment(additionalStopInfo.dateTime).toISOString(),
        // for first flight, if dateTime changes, trackedFlight should also reset
        ...(stop.stopIndex === 1 && {
          flightNumber: null,
          trackedFlight: null,
        }),
      }),
    });
  };

  const handleStopFlightSearch = () => {
    const currentStopErrors = {
      airport: !stopState.airport ? "Please select an airport" : "",
      airline: !stopState.airline ? "Please select an airline" : "",
      flightNumber: !flightNumberInput ? "Please enter flight number" : "",
    };

    const hasErrors =
      Object.values(currentStopErrors).some((value) => value.length) ||
      !firstStop.dateTime;

    if (hasErrors) {
      snackbar.warning("Please enter missing information to search flights", {
        snackbarColor: white,
        iconColor: errorRed,
        textColor: black,
      });

      return;
    }

    const hasStopBeenUpdated =
      prevFirstStopDateTime !== firstStop.dateTime ||
      (stopState.stopIndex === 1
        ? stopState.trackedFlight?.destination.airport.icaoCode
        : stopState.trackedFlight?.origin.airport.icaoCode) !==
        stopState.airport.icaoCode ||
      stop.trackedFlight?.airline.icaoCode !== stopState.airline.icaoCode ||
      stopState.trackedFlight?.flightNumber !== stopState.flightNumber;
    if (!hasStopBeenUpdated) return;

    loadPotentialTrackedFlight({
      variables: {
        flightNumber: Number(flightNumberInput),
        firstStopDateTime: firstStop.dateTime,
        airline: stopState.airline?.icaoCode,
        ...{
          ...(stopState.stopIndex === stopsLength
            ? {
                departureAirport: stopState.airport?.icaoCode,
              }
            : {
                arrivalAirport: stopState.airport?.icaoCode,
              }),
        },
      },
    });
  };

  const handleMultipleFlightSelect = (selectedFlight: TrackedFlight) => {
    setStopState({
      ...stopState,
      trackedFlight: selectedFlight,
      flightNumber:
        selectedFlight.flightNumber || selectedFlight.actualFlightNumber,
    });
    setMultipleFlightsDialogData(undefined);
  };

  const handleFlightNumberInputChange = (e) => {
    setFlightNumberInput(e.target.value);
    setStopState({
      ...stopState,
      trackedFlight: null,
    });
  };

  const handleDebouncedUpdateStop = useCallback(() => {
    setSaveIndicatorState("loading");

    const inputStop: UpdateStopInput | Partial<Stop> = {
      ...debouncedStop,
    };

    if (inputStop.airport) {
      // sets airportIcao code which is stored in db to link to airport
      inputStop.location = inputStop.airport.airportName;
      inputStop.locationAlias = null;
      inputStop.airportIcao = inputStop.airport.icaoCode;
    }

    if (inputStop.airline) {
      inputStop.airlineIcao = inputStop.airline.icaoCode;
    }

    // remove additional fields from being sent to update stop mutation
    delete inputStop.airport;
    delete inputStop.airline;
    delete inputStop.stopIndex;
    delete inputStop.createdAt;
    delete inputStop.updatedAt;
    delete inputStop.variant;
    delete inputStop.__typename;

    updateStop({
      variables: {
        input: inputStop,
      },
    });
  }, [debouncedStop, setSaveIndicatorState, updateStop]);

  const handleClickRefreshFlight = async () => {
    setRefreshingFlight(true);

    // TODO: replace with new call
    updateTrackedFlight({
      variables: {
        input: {
          flightNumber:
            stopState.trackedFlight.flightNumber ||
            stopState.trackedFlight.actualFlightNumber,
          trackedFlightId: stopState.trackedFlight.id,
        },
      },
    });
  };

  const handleStopDateTimeChange = (date) => {
    setStopState({
      ...stopState,
      trackedFlight: null,
      dateTime: applyUTCOffsetToTime(moment(date), "add").toISOString(),
    });
  };

  const handleLocationAliasChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let newValue = event.target.value;
    if (newValue.length > 35) {
      return;
    }
    setStopState({
      ...stopState,
      locationAlias: newValue,
    });
  };

  // effects
  useEffect(() => {
    // prevents updating on initial load
    if (!initialLoadFlag.current) {
      initialLoadFlag.current = true;
    } else {
      handleDebouncedUpdateStop();
    }
  }, [debouncedStop, handleDebouncedUpdateStop]);

  useEffect(() => {
    initialLoadFlag.current = false;
    setStopState(stop);
  }, [stop]);

  // time manually adjusted to display properly on DateTimePicker
  const adjustedStopDateTime = firstStop?.dateTime
    ? applyUTCOffsetToTime(firstStop.dateTime, "subtract").toISOString()
    : null;

  if (!stopState) {
    return null;
  }

  return (
    <>
      <Box>
        {/* first stop date & time */}
        {stop.stopIndex === 1 && !isShuttleReservation && (
          <Box mb={4}>
            <Box mb={1.5}>
              <Typography variant="h5">Date & Time</Typography>
            </Box>
            <Box mb={1}>
              <Divider />
            </Box>
            <LabeledInlineDateTimePicker
              label="Pick-up Date & Time"
              name="dateTime"
              value={adjustedStopDateTime}
              onChange={handleStopDateTimeChange}
              errorText="Stop Date/Time is required"
            />
          </Box>
        )}

        <Box
          display="flex"
          flex="1"
          height="100%"
          flexDirection="column"
          pt={1}
        >
          {/* stop header */}
          <Box
            display="flex"
            flex="1"
            justifyContent="space-between"
            flexDirection={isMobileView ? "column" : "row"}
            alignItems={isMobileView ? "flex-start" : "center"}
            mb={1}
          >
            <Box mr={2} mb={1}>
              <Typography variant="h5" style={{ whiteSpace: "nowrap" }}>
                {stopLabel}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <AddressAirportToggle
                onChange={handleVariantClick}
                value={nextStop?.variant || variant}
              />

              {/* can only remove last stop right now */}
              <Box display="flex">
                {showIncorrectStopOrderWarning && (
                  <MoovsTooltip tooltipText="Date & time falls before a previous date & time">
                    <IconButton size="large">
                      <WarningIcon color={orange} />
                    </IconButton>
                  </MoovsTooltip>
                )}
                {showMissingDropOffDateTimeWarning && (
                  <MoovsTooltip tooltipText="No date & time but intermediates stops are scheduled. ETA may be inaccurate">
                    <IconButton size="large">
                      <WarningIcon color={orange} />
                    </IconButton>
                  </MoovsTooltip>
                )}
                <AdditionalStopInfoButtonPanel
                  stopLabel={stopLabel}
                  dateTime={stop.stopIndex !== 1 && stopState.dateTime}
                  groupSize={stopState.groupSize}
                  note={stopState.note}
                  onEditAdditionalStopInfoClick={() =>
                    setAdditionalStopInfoOpen(true)
                  }
                  editDateTimeDisabled={
                    isShuttlePickUpOrDropOffStop || isShuttleReturn
                  }
                />
                {/* edit additional info / remove stop buttons */}
                {/* Do not display shuttle pick-up or drop-off additional stop dialog as date/time is editable above stop block */}
                {!isShuttlePickUpOrDropOffStop && (
                  <EditRemoveStopButtons
                    canRemoveStop={canRemoveStop}
                    canEditStop={!isShuttleReturn} // cannot edit stop times on shuttle return since return stops do not have dateTimes
                    onRemoveStopClick={handleRemoveStop}
                    onEditAdditionalStopInfoClick={() =>
                      setAdditionalStopInfoOpen(true)
                    }
                    stopLabel={stopLabel}
                  />
                )}
              </Box>
            </Box>
          </Box>

          <Box mb={1}>
            <Divider />
          </Box>

          <Droppable droppableId={stop.id} direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={{
                  backgroundColor: snapshot.isDraggingOver
                    ? alabaster
                    : "transparent",
                  borderRadius: "4px",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  // prevents background from going on top of dragging item
                  zIndex: snapshot.isDraggingOver ? 900 : 1000,
                }}
                {...provided.droppableProps}
              >
                {/* temp draggable value */}
                {/* used to visually rearrange stops while dragging */}
                {nextStop && !isDestinationIndex && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    flex="1"
                    alignItems="center"
                    bgcolor={white}
                    py={1}
                    pl={2}
                    pr={0}
                    borderRadius="4px"
                  >
                    <Box
                      mr={1.5}
                      height={56}
                      display="flex"
                      alignItems="center"
                    >
                      <RearrangeIcon />
                    </Box>
                    <Box display="flex" flex="1" width="100%">
                      <LabeledInlineInput
                        required
                        label={nextStopLabel}
                        value={nextStopValue}
                      />
                    </Box>
                  </Box>
                )}
                <Draggable index={stop.stopIndex - 1} draggableId={stop.id}>
                  {(provided, draggableSnapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      {/* address/airport reorderable input */}
                      <Box
                        display={nextStop && !isSourceIndex ? "none" : "flex"}
                        flexDirection="row"
                        flex="1"
                        alignItems="center"
                        bgcolor={white}
                        boxShadow={
                          draggableSnapshot.isDragging
                            ? "0px 0px 30px rgba(0, 0, 0, 0.08)"
                            : "none"
                        }
                        py={1}
                        pl={2}
                        pr={draggableSnapshot.isDragging ? 2 : 0}
                        borderRadius="4px"
                      >
                        <Box
                          mr={1.5}
                          height={56}
                          display="flex"
                          alignItems="center"
                          {...provided.dragHandleProps}
                          onMouseDown={handleBlur}
                        >
                          <RearrangeIcon />
                        </Box>
                        <Box display="flex" flex="1" width="100%">
                          {/* address */}
                          <Box
                            display={variant === "address" ? "flex" : "none"}
                            flex="1"
                          >
                            <LabeledInlineLocationAutocomplete
                              // labelSize="xs" commented out until reorder functionality
                              label="Address"
                              name="location"
                              inputRef={addressRef}
                              value={stopState.location}
                              onChange={(value) => {
                                setStopState({
                                  ...stopState,
                                  location: value.description,
                                });
                              }}
                              errorText="Stop location is required"
                              required
                              suggestedAddressInfo={suggestedAddressInfo}
                            />
                          </Box>
                          {/* airport */}
                          <Box
                            display={variant === "airport" ? "flex" : "none"}
                            flex="1"
                          >
                            <LabeledInlineAirportAutocomplete
                              // labelSize="xs" commented out until reorder functionality
                              label="Airport"
                              inputRef={airportRef}
                              onChange={(airport) => {
                                setStopState({
                                  ...stopState,
                                  airport,
                                  flightNumber: null,
                                  trackedFlight: null,
                                  ...(airport === null && {
                                    airportIcao: null,
                                  }),
                                });
                                setFlightNumberInput("");
                              }}
                              value={stopState.airport}
                              error={!stopState.airport}
                              required
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        bgcolor={white}
                        pl={2}
                        pb={draggableSnapshot.isDragging ? 2 : 0}
                        borderRadius="4px"
                      >
                        {isShuttleReservation && variant === "address" && (
                          <LabeledInlineCharacterLimitedTextInput
                            label="Location Name"
                            name="locationAlias"
                            value={stopState.locationAlias}
                            preventCharacterCountUpdatePastLimit
                            sx={{ pl: 4.5 }}
                            disabled={updateStopLoading}
                            onChange={handleLocationAliasChange}
                          />
                        )}
                      </Box>
                    </div>
                  )}
                </Draggable>
                <Box visibility="hidden" height={0}>
                  {provided.placeholder}
                </Box>
              </div>
            )}
          </Droppable>
        </Box>

        {variant === "airport" && (
          <Box
            px={2}
            py={3}
            mb={2}
            style={{ backgroundColor: alabaster, borderRadius: "4px" }}
          >
            {/* airport stop type additional info fields */}
            {variant === "airport" && (
              <>
                <Box>
                  <LabeledInlineAirlineAutocomplete
                    label="Airline"
                    onChange={(airline) => {
                      setStopState({
                        ...stopState,
                        airline,
                        flightNumber: null,
                        trackedFlight: null,
                        ...(airline === null && {
                          airlineIcao: null,
                        }),
                      });
                      setFlightNumberInput("");
                    }}
                    value={stopState.airline}
                    error={!stopState.airline}
                  />
                </Box>

                {/* flight */}
                <Box mt={1}>
                  <LabeledInlineFlightNumberInput
                    label="Flight"
                    value={flightNumberInput}
                    onFlightNumberInputChange={handleFlightNumberInputChange}
                    airlineIata={stopState.airline?.iataCode}
                    error={!flightNumberInput}
                    isSearchingForFlight={potentialTrackedFlightLoading}
                  />
                </Box>

                {stopState.trackedFlight && !potentialTrackedFlightLoading && (
                  <Box mt={3}>
                    <FlightInfoCard
                      trackedFlight={stopState.trackedFlight}
                      refreshFlightProps={{
                        onClickRefreshFlight: handleClickRefreshFlight,
                        isRefreshingFlight: refreshingFlight,
                      }}
                    />
                  </Box>
                )}
                {potentialTrackedFlightLoading && (
                  <Box
                    mt={3}
                    py={15}
                    style={{ backgroundColor: grayLight, borderRadius: "4px" }}
                    display="flex"
                    justifyContent="center"
                  >
                    <CircularProgress />
                  </Box>
                )}
              </>
            )}
          </Box>
        )}
      </Box>

      <RemoveDialog
        open={variantDialogOpen}
        onRemove={handleVariantChange}
        onClose={() => setVariantDialogOpen(false)}
        title={`Switch to ${variantDialogType}?`}
        body={
          variantDialogType === "airport"
            ? `Switching to airport will delete address
            information for this stop. Do you want to switch
            to airport?`
            : `Switching to address will delete airport
            information for this stop. Do you want to switch
            to address?`
        }
        removeButtonText="Switch"
      />

      <AdditionalStopInfoDialog
        stopLabel={stopLabel}
        open={additionalStopInfoOpen}
        onClose={() => setAdditionalStopInfoOpen(false)}
        stop={{ ...stopState, stopIndex: stop.stopIndex }}
        onSave={handleAdditionalStopInfoChange}
        clearable={stop.stopIndex !== 1}
        tripCategory={tripCategory}
      />

      {!!multipleFlightsDialogData && (
        <MultipleFlightsDialog
          open={!!multipleFlightsDialogData}
          onClose={() => setMultipleFlightsDialogData(undefined)}
          onAccept={handleMultipleFlightSelect}
          data={multipleFlightsDialogData}
        />
      )}
    </>
  );
}

export default TripInfoUpdateBlockItem;
