import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import map from "lodash/map";

import {
  Box,
  Select,
  Typography,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";

import { useOperator, useScreenSize, useSnackbar } from "globals/hooks";
import { grayDark, black } from "design-system/colors";
import { ChevronDownIcon, CheckIcon } from "design-system/icons";
import { UPDATE_OPERATOR_SETTINGS } from "globals/graphql";
import LabeledSwitchInput from "components/settings/general/LabeledSwitchInput";
import { TimeDuration } from "types";

const AUTOMATION_TIME_VALUES = [...Array(24).keys()]; // 0-23

const AUTOMATION_TIME_TYPES = {
  [TimeDuration.Days]: {
    value: TimeDuration.Days,
    label: "Days",
    singularLabel: "Day",
  },
  [TimeDuration.Hours]: {
    value: TimeDuration.Hours,
    label: "Hours",
    singularLabel: "Hour",
  },
};

type AutomatedRequestChangesProps = {
  setSaveIndicatorState: (
    savedState: "default" | "loading" | "saved" | "error"
  ) => void;
};

function AutomatedRequestChanges(props: AutomatedRequestChangesProps) {
  const { setSaveIndicatorState } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const snackbar = useSnackbar();
  const operator = useOperator();

  // derived state
  const {
    customerChangeAutomationEnabled,
    customerChangeAutomationTimeType,
    customerChangeAutomationTimeValue,
  } = operator.settings;

  // state
  const [automationEnabled, setAutomationEnabled] = useState(
    customerChangeAutomationEnabled
  );
  const [timeSetting, setTimeSetting] = useState({
    customerChangeAutomationTimeValue,
    customerChangeAutomationTimeType,
  });

  // mutations
  const [updateOperatorSettings] = useMutation(UPDATE_OPERATOR_SETTINGS, {
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError(error) {
      setSaveIndicatorState("error");
      snackbar.error("Error updating settings");
    },
  });

  // event handlers
  const handleTimeChange = (event) => {
    setSaveIndicatorState("loading");

    setTimeSetting((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));

    updateOperatorSettings({
      variables: {
        input: {
          [event.target.name]: event.target.value,
        },
      },
    });
  };

  const handleEnabledChange = () => {
    setSaveIndicatorState("loading");

    setAutomationEnabled((prev) => !prev);

    updateOperatorSettings({
      variables: {
        input: {
          customerChangeAutomationEnabled: !automationEnabled,
        },
      },
    });
  };

  const isPlural =
    timeSetting.customerChangeAutomationTimeValue === 0 ||
    timeSetting.customerChangeAutomationTimeValue > 1;

  const description = `Any customer request for changes will be automated if made ${
    timeSetting.customerChangeAutomationTimeValue
  } ${
    isPlural
      ? AUTOMATION_TIME_TYPES[
          timeSetting.customerChangeAutomationTimeType
        ].label.toLowerCase()
      : AUTOMATION_TIME_TYPES[
          timeSetting.customerChangeAutomationTimeType
        ].singularLabel.toLowerCase()
  } or more before trip pickup time`;

  return (
    <Box mt={4}>
      <Typography variant="h3" mb={2}>
        Request Changes to Trip
      </Typography>

      <Box display="flex" flexDirection="column">
        <Box ml={-1} mb={3}>
          <LabeledSwitchInput
            labelPlacement="start"
            name="customerChangeAutomationEnabled"
            checked={automationEnabled}
            onChange={handleEnabledChange}
            text="Automate trip updates requested by customer"
            subText={
              <Box maxWidth={538}>
                <Typography variant="caption">
                  When this is on, any trip update that is requested by the
                  customer will automatically be updated if request is made
                  prior to your cutoff time selected
                </Typography>
              </Box>
            }
          />
        </Box>

        <Box flex="1" {...(isMobileView && { mb: 1.5 })}>
          <Typography variant="subtitle2">Automation Cutoff Period</Typography>

          <Box display="flex" flex="1" mt={1}>
            <Box
              maxWidth={isMobileView ? "100%" : 85}
              display="flex"
              flex="1"
              mr={1}
            >
              {/* value select (0-23) */}
              <Select
                value={timeSetting.customerChangeAutomationTimeValue}
                renderValue={() =>
                  timeSetting.customerChangeAutomationTimeValue
                }
                fullWidth
                variant="outlined"
                name="customerChangeAutomationTimeValue"
                onChange={handleTimeChange}
                IconComponent={() => (
                  <Box
                    position="relative"
                    top="5px"
                    right="20px"
                    sx={[
                      {
                        pointerEvents: "none",
                      },
                    ]}
                  >
                    <ChevronDownIcon color={grayDark} size="small" />
                  </Box>
                )}
              >
                {AUTOMATION_TIME_VALUES.map((value) => (
                  <MenuItem value={value} key={value}>
                    <Box mr={2}>
                      <ListItemText primary={value} />
                    </Box>
                    {value ===
                      timeSetting.customerChangeAutomationTimeValue && (
                      <ListItemIcon>
                        <CheckIcon color={black} size="small" />
                      </ListItemIcon>
                    )}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            {/* type select (days/hours) */}
            <Box maxWidth={isMobileView ? "100%" : 121} display="flex" flex="1">
              <Select
                value={timeSetting.customerChangeAutomationTimeType}
                renderValue={() => {
                  const { label, singularLabel } =
                    AUTOMATION_TIME_TYPES[
                      timeSetting.customerChangeAutomationTimeType
                    ];

                  return isPlural ? label : singularLabel;
                }}
                fullWidth
                variant="outlined"
                name="customerChangeAutomationTimeType"
                onChange={handleTimeChange}
                IconComponent={() => (
                  <Box
                    position="relative"
                    top="5px"
                    right="20px"
                    sx={[
                      {
                        pointerEvents: "none",
                      },
                    ]}
                  >
                    <ChevronDownIcon color={grayDark} size="small" />
                  </Box>
                )}
              >
                {map(AUTOMATION_TIME_TYPES, (type) => {
                  const { value, label, singularLabel } = type;

                  return (
                    <MenuItem value={value} key={value}>
                      <Box mr={2}>
                        <ListItemText
                          primary={isPlural ? label : singularLabel}
                        />
                      </Box>
                      {value ===
                        timeSetting.customerChangeAutomationTimeType && (
                        <ListItemIcon>
                          <CheckIcon color={black} size="small" />
                        </ListItemIcon>
                      )}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>

            {!isMobileView && (
              <Box display="flex" alignItems="center" ml={2} maxWidth={320}>
                <Typography variant="caption" color={grayDark}>
                  {description}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>

        {isMobileView && (
          <Box width="100%">
            <Typography variant="caption" color={grayDark}>
              {description}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default AutomatedRequestChanges;
