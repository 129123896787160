import React from "react";

import { Typography } from "@mui/material";

import VehicleFormSection from "components/vehicles/VehicleFormSection";
import { grayDark } from "design-system/colors";
import { CreateVehicle } from "../../../types";
import DisplayBookingToolRequestMobile from "design-system/images/DisplayBookingToolRequestMobile.png";
import DisplayBookingToolRequestDesktop from "design-system/images/DisplayBookingToolRequestDesktop.png";
import BookingToolBRAMobile from "design-system/images/BookingToolBRAMobile.png";
import BookingToolBRADesktop from "design-system/images/BookingToolBRADesktop.png";
import BookingToolBlockQuoteDesktop from "design-system/images/BookingToolBlockQuoteDesktop.png";
import BookingToolBlockQuoteMobile from "design-system/images/BookingToolBlockQuoteMobile.png";
import BookingToolBlockReservationDesktop from "design-system/images/BookingToolBlockReservationDesktop.png";
import BookingToolBlockReservationMobile from "design-system/images/BookingToolBlockReservationMobile.png";
import { useAnalytics, useScreenSize } from "globals/hooks";
import {
  BookingToolSettingSection,
  BookingToolSettingSectionWithPlanUpgrade,
} from "./components";

type BookingToolSettingsProps = {
  vehicle: CreateVehicle;
  setVehicle: React.Dispatch<React.SetStateAction<CreateVehicle>>;
};

function BookingToolSettings(props: BookingToolSettingsProps) {
  const { vehicle, setVehicle } = props;

  // hooks
  const { isSmallTabletView } = useScreenSize();
  const { track } = useAnalytics();

  // event handler
  const handleBookingToolSettingsToggle = (e) => {
    let { checked, name } = e.target;

    if (name === "enableBaseRateAutomationBookingTool") {
      track("vehicleBookingTool_braUpdated", {
        toggleState: checked ? "On" : "Off",
      });
    }

    setVehicle({ ...vehicle, [name]: checked });
  };

  const handleVehicleSettingsToggle = (e) => {
    const { name, checked } = e.target;

    if (name === "conflictBlockQuote") {
      checked
        ? track("vehicleBookingTool_blockQuoteEnabled")
        : track("vehicleBookingTool_blockQuoteDisabled");
    } else if (name === "conflictBlockReservation") {
      checked
        ? track("vehicleBookingTool_blockReservationEnabled")
        : track("vehicleBookingTool_blockReservationDisabled");
    }

    setVehicle({
      ...vehicle,
      settings: {
        ...vehicle.settings,
        [name]: checked,
      },
    });
  };

  return (
    <VehicleFormSection title="Settings">
      {/* DisplayBookingTool */}
      <BookingToolSettingSection
        vehicle={vehicle}
        handleBookingToolSettingsToggle={handleBookingToolSettingsToggle}
        mobileImage={DisplayBookingToolRequestMobile}
        desktopImage={DisplayBookingToolRequestDesktop}
        name="available"
        title="Display on Customer Portal"
        subtext="When this is on, vehicle will be displayed on customer portal for customers to request"
      />

      {/* Enable BRA */}
      <BookingToolSettingSection
        vehicle={vehicle}
        handleBookingToolSettingsToggle={handleBookingToolSettingsToggle}
        mobileImage={BookingToolBRAMobile}
        desktopImage={BookingToolBRADesktop}
        name="enableBaseRateAutomationBookingTool"
        title="Enable Base Rate Automation on Customer Portal"
        subtext="When this is on, price will be displayed and customers can book vehicle without requesting a quote"
      />

      {/* Block Quote */}
      <BookingToolSettingSectionWithPlanUpgrade
        value={vehicle.settings.conflictBlockQuote}
        handleVehicleSettingsToggle={handleVehicleSettingsToggle}
        mobileImage={BookingToolBlockQuoteMobile}
        desktopImage={BookingToolBlockQuoteDesktop}
        name="conflictBlockQuote"
        imgWidth="100%"
        title="Block quote requests when vehicle is unavailable"
        subtext={
          <Typography
            sx={{
              fontSize: "12px",
              color: grayDark,
            }}
          >
            When this is on, customer will not be able to{" "}
            <Typography fontWeight="600" fontSize="12px" display="inline">
              submit a quote request
            </Typography>{" "}
            if there is a trip conflict. Customer will be instructed to choose
            another vehicle, update pickup time or contact you directly.
          </Typography>
        }
      />

      {/* Block Reservation */}
      <BookingToolSettingSectionWithPlanUpgrade
        value={vehicle.settings.conflictBlockReservation}
        handleVehicleSettingsToggle={handleVehicleSettingsToggle}
        mobileImage={BookingToolBlockReservationMobile}
        desktopImage={BookingToolBlockReservationDesktop}
        name="conflictBlockReservation"
        title="Block reservations when vehicle is unavailable"
        imgWidth={isSmallTabletView ? "80%" : "60%"}
        subtext={
          <Typography
            sx={{
              fontSize: "12px",
              color: grayDark,
            }}
          >
            When this is on, customer will not be able to{" "}
            <Typography fontWeight="600" fontSize="12px" display="inline">
              reserve
            </Typography>{" "}
            if there is a trip conflict. Customer will be instructed to choose
            another vehicle, update pickup time or contact you directly.
          </Typography>
        }
      />
    </VehicleFormSection>
  );
}

export default BookingToolSettings;
