import React, { useMemo } from "react";
import { Box, CircularProgress } from "@mui/material";
import { StringParam, useQueryParam } from "use-query-params";
import { ApolloQueryResult, useQuery } from "@apollo/client";

import { useScreenSize } from "globals/hooks";
import { flattenReturnTrips } from "globals/utils/trips";
import { Request } from "types";
import { MOBILE_ACTION_BAR_HEIGHT } from "../ActionButtonPanel/ActionButtonPanel.mobile";
import NoTripsText from "./components/NoTripsText";
import TripSummaryBanner from "./components/TripSummaryBanner";
import TripSummaryHeader from "./components/TripSummaryHeader";
import TripDetails from "pages/requests/RequestOverview/ReservationOverview/TripDetails";
import { LOAD_TRIP_SUMMARY_QUERY } from "globals/graphql";

type TripSummaryProps = {
  request: Request;
  onCloseTripSummaryClick: () => void;
  requestRefetch: (variables?: {
    id: string;
  }) => Promise<ApolloQueryResult<any>>;
};

function TripSummary(props: TripSummaryProps) {
  const { request, onCloseTripSummaryClick, requestRefetch } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const [currentTripId] = useQueryParam("tripId", StringParam);

  // derived state
  const requestWithFlattenedTrips = useMemo(() => {
    return { ...request, trips: flattenReturnTrips(request.trips) };
  }, [request]);
  const hasNoTrips = !requestWithFlattenedTrips.trips.length;

  const { data, loading: tripSummaryLoading } = useQuery(
    LOAD_TRIP_SUMMARY_QUERY,
    {
      variables: {
        id: currentTripId,
      },
      skip: !currentTripId,
      fetchPolicy: "cache-first",
      ...(currentTripId && { pollInterval: 60 * 1000 }), // every 60 seconds
    }
  );

  const trip = data?.node;

  // render methods
  if ((!trip && tripSummaryLoading) || !trip) {
    return (
      <Box mt={8} width="100%" display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (hasNoTrips)
    return <NoTripsText orderNumber={request?.orderNumber || ""} />;

  return (
    <Box
      sx={{
        width: isMobileView ? "auto" : "100%",
        overflow: "auto",
        mb: isMobileView ? `${MOBILE_ACTION_BAR_HEIGHT}px` : 0,
      }}
    >
      <Box>
        {/* header */}
        <TripSummaryHeader
          onCloseTripSummaryClick={onCloseTripSummaryClick}
          request={requestWithFlattenedTrips}
          trip={trip}
        />

        {/* banner */}
        <Box sx={{ m: 2 }}>
          <TripSummaryBanner request={requestWithFlattenedTrips} trip={trip} />
        </Box>

        {/* body */}
        <Box sx={{ m: 2 }}>
          <TripDetails
            request={requestWithFlattenedTrips}
            trip={trip}
            requestRefetch={requestRefetch}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default TripSummary;
