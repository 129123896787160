import React from "react";
import { useMutation } from "@apollo/client";

import { Box, Typography } from "@mui/material";

import LabeledSwitchInput from "../LabeledSwitchInput";
import { useOperator, useSnackbar } from "../../../../globals/hooks";
import {
  EmptyVariables,
  ENABLE_TEXT_NOTIFICATIONS_FOR_TRIP_REMINDER,
  DISABLE_TEXT_NOTIFICATIONS_FOR_TRIP_REMINDER,
  DISABLE_TEXT_FOR_DRIVER_STATUS_UPDATES,
  ENABLE_TEXT_FOR_DRIVER_STATUS_UPDATES,
  UPDATE_OPERATOR_SETTINGS,
} from "../../../../globals/graphql";
import { getErrorMessage } from "moovsErrors/getErrorMessage";

type ContactsToggleBlockProps = {
  setSaveIndicatorState: (
    savedState: "default" | "loading" | "saved" | "error"
  ) => void;
};

function ContactsToggleBlock(props: ContactsToggleBlockProps) {
  const { setSaveIndicatorState } = props;

  // hooks
  const snackbar = useSnackbar();
  const {
    tripReminderTextsEnabled,
    driverStatusUpdateTextsEnabled,
    twilioPhoneNumber,
    settings: { memberChangedStatusTextEnabled },
  } = useOperator();

  // constants
  const isChatConnected = !!twilioPhoneNumber;

  // mutations
  const [enableTripReminderTexts] = useMutation(
    ENABLE_TEXT_NOTIFICATIONS_FOR_TRIP_REMINDER,
    {
      onCompleted() {
        setSaveIndicatorState("saved");
      },
      onError(error) {
        setSaveIndicatorState("error");
        const errorMessage =
          getErrorMessage(error) || "Error enabling trip reminder texts.";

        snackbar.error(errorMessage);
      },
      variables: EmptyVariables,
    }
  );

  const [disableTripReminderTexts] = useMutation(
    DISABLE_TEXT_NOTIFICATIONS_FOR_TRIP_REMINDER,
    {
      onCompleted() {
        setSaveIndicatorState("saved");
      },
      onError(error) {
        setSaveIndicatorState("error");
        const errorMessage =
          getErrorMessage(error) || "Error disabling trip reminder texts.";

        snackbar.error(errorMessage);
      },
      variables: EmptyVariables,
    }
  );

  const [enableTextForDriverStatusUpdates] = useMutation(
    ENABLE_TEXT_FOR_DRIVER_STATUS_UPDATES,
    {
      onCompleted() {
        setSaveIndicatorState("saved");
      },
      onError(error) {
        setSaveIndicatorState("error");
        snackbar.error(
          "error enabling text notifications for driver status updates"
        );
      },
      variables: EmptyVariables,
    }
  );

  const [disableTextForDriverStatusUpdates] = useMutation(
    DISABLE_TEXT_FOR_DRIVER_STATUS_UPDATES,
    {
      onCompleted() {
        setSaveIndicatorState("saved");
      },
      onError(error) {
        setSaveIndicatorState("error");
        snackbar.error(
          "error disabling text notifications for driver status updates"
        );
      },
      variables: EmptyVariables,
    }
  );

  const [updateOperatorSettings] = useMutation(UPDATE_OPERATOR_SETTINGS, {
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError() {
      setSaveIndicatorState("error");
      snackbar.error(
        "Error updating setting for text notifications for trip status updates"
      );
    },
  });

  // event handlers
  const handleTripReminderTextsChange = () => {
    setSaveIndicatorState("loading");

    tripReminderTextsEnabled
      ? disableTripReminderTexts()
      : enableTripReminderTexts();
  };

  const handleDriverTriggerTripStatusTextsChange = () => {
    setSaveIndicatorState("loading");

    driverStatusUpdateTextsEnabled
      ? disableTextForDriverStatusUpdates()
      : enableTextForDriverStatusUpdates();
  };

  const handleMemberTriggerTripStatusTextsChange = () => {
    setSaveIndicatorState("loading");
    updateOperatorSettings({
      variables: {
        input: {
          memberChangedStatusTextEnabled: !memberChangedStatusTextEnabled,
        },
      },
    });
  };

  return (
    <>
      <Typography variant="h4">Contacts</Typography>
      <Box mt={2}>
        <LabeledSwitchInput
          name="tripReminderTexts"
          checked={tripReminderTextsEnabled}
          disabled={!isChatConnected}
          onChange={handleTripReminderTextsChange}
          text="Send Upcoming Trip Reminders to Booking Contact"
          subText="When this is on, the booking contact will receive text messages to remind them of the upcoming trip 24 hours and 1 hour prior to the pick-up time."
        />
      </Box>
      <Box mt={2.5}>
        <LabeledSwitchInput
          name="driverStatusUpdates"
          checked={driverStatusUpdateTextsEnabled}
          disabled={!isChatConnected}
          onChange={handleDriverTriggerTripStatusTextsChange}
          text="Send Driver-Triggered Trip Status Updates to Passenger"
          subText="When this is on, the passenger will receive a text message about the trip status (on the way, on location, done) when the driver updates the trip status in the driver app or driver link."
        />
      </Box>
      <Box mt={2.5}>
        <LabeledSwitchInput
          name="memberStatusUpdates"
          checked={memberChangedStatusTextEnabled}
          disabled={!isChatConnected}
          onChange={handleMemberTriggerTripStatusTextsChange}
          text="Send Member-Triggered Trip Status Updates to Passenger"
          subText="When this is on, the passenger will receive a text message about the trip status (on the way, on location, done) when a member updates the trip status on the reservation page or dispatch page."
        />
      </Box>
    </>
  );
}

export default ContactsToggleBlock;
