import React from "react";

import { Box, FormControl, FormLabel, Switch, Typography } from "@mui/material";
import { grayDark } from "design-system/colors";

type LabeledSwitchInputProps = {
  name?: string;
  disabled?: boolean;
  checked: boolean;
  onChange: (event?: any, any?: any) => void;
  text: React.ReactNode;
  subText: React.ReactNode;
  labelPlacement?: "start";
};

function LabeledSwitchInput(props: LabeledSwitchInputProps) {
  const { checked, disabled, onChange, text, subText, name, labelPlacement } =
    props;

  if (labelPlacement === "start") {
    return (
      <FormControl>
        <Box
          display="flex"
          flexDirection="row"
          flex="1"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" flexDirection="column" ml={1}>
            <Box mb={0.5}>
              <FormLabel htmlFor={name}>
                <Typography variant="body2">{text}</Typography>
              </FormLabel>
            </Box>
            <Typography variant="caption" style={{ color: grayDark }}>
              {subText}
            </Typography>
          </Box>
          <Switch
            id={name}
            checked={checked}
            disabled={disabled}
            onChange={onChange}
            name={name}
            color="primary"
          />
        </Box>
      </FormControl>
    );
  }

  return (
    <FormControl>
      <Box display="flex">
        <Switch
          id={name}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          name={name}
          color="primary"
        />

        <Box display="flex" flexDirection="column" ml={1}>
          <Box mb={0.5}>
            <FormLabel htmlFor={name}>
              <Typography variant="body2">{text}</Typography>
            </FormLabel>
          </Box>
          <Box>
            <Typography variant="caption" style={{ color: grayDark }}>
              {subText}
            </Typography>
          </Box>
        </Box>
      </Box>
    </FormControl>
  );
}

export default LabeledSwitchInput;
