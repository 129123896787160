import React, { useState, MouseEvent, ReactElement } from "react";

import { Box, Link, Tooltip, Typography } from "@mui/material";

import { moovsBlue, granite } from "../../design-system/colors";
import { InfoIcon } from "../../design-system/icons";
import { useScreenSize } from "../../globals/hooks";
import { useHistory } from "react-router-dom";

export type MoovsTooltipBaseProps = {
  tooltipText: string;
  tooltipSubtext?: React.ReactNode;
  tooltipAdditionalText?: string;
  tooltipPretext?: string;
  showcaseMainText?: boolean;
  children?: ReactElement;
  hidden?: boolean;
  placement?:
    | "top"
    | "right"
    | "bottom"
    | "left"
    | "left-start"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start";
  noWrap?: boolean;
};

type MoovsTooltipProps =
  | (MoovsTooltipBaseProps & {
      linkPath: string;
      linkText: string;
      linkColor: string;
    })
  | (MoovsTooltipBaseProps & {
      linkPath?: never;
      linkText?: never;
      linkColor?: never;
    });

function MoovsTooltip(props: MoovsTooltipProps) {
  const {
    tooltipText,
    tooltipSubtext,
    tooltipAdditionalText,
    tooltipPretext,
    children,
    showcaseMainText,
    hidden,
    noWrap,
    linkPath,
    linkText,
    linkColor,
  } = props;

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { isMobileView } = useScreenSize();
  const history = useHistory();

  // prevent option from being selected when touching tooltip on mobile
  const handleMobileTooltipOpen = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  const content = children || (
    <Box display="flex" onClick={isMobileView ? handleMobileTooltipOpen : null}>
      <InfoIcon color={tooltipOpen ? moovsBlue : granite} size="small" />
    </Box>
  );

  if (hidden) return children;

  return (
    <Tooltip
      enterTouchDelay={0}
      leaveTouchDelay={3000}
      onOpen={() => setTooltipOpen(true)}
      onClose={() => setTooltipOpen(false)}
      placement={props.placement || "top"}
      tabIndex={0}
      title={
        <Box
          p={0}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box display="flex" flexDirection="row">
            {tooltipPretext && (
              <Typography
                variant="h6"
                style={{ fontWeight: 400, whiteSpace: "pre" }}
              >
                {`${tooltipPretext} `}
              </Typography>
            )}
            <Typography
              variant="caption"
              noWrap={noWrap}
              {...(showcaseMainText && { style: { fontWeight: 700 } })}
              {...(noWrap && { style: { fontSize: "0.7rem" } })} // noWrap spacing fix
            >
              {tooltipText}
            </Typography>
          </Box>
          <Typography
            variant="caption"
            {...(!showcaseMainText && { style: { fontWeight: 700 } })}
          >
            {tooltipSubtext}
          </Typography>
          <Typography
            variant="caption"
            {...(!showcaseMainText && { style: { fontWeight: 700 } })}
          >
            {tooltipAdditionalText}
          </Typography>
          {linkPath && (
            <Link
              component="button"
              variant="body2"
              underline="none"
              style={{ fontWeight: 500 }}
              color={linkColor}
              onClick={() => history.push(linkPath)}
            >
              {linkText}
            </Link>
          )}
        </Box>
      }
    >
      <Box>{content}</Box>
    </Tooltip>
  );
}

export default MoovsTooltip;
