/**
 * Interface for authenticating with server /auth endpoint.
 */
import axios from "axios";
import { getServerLink } from "globals/utils/getServerLink";

const getAuthEndpoint = () => `${getServerLink()}/auth/operator`;

axios.defaults.withCredentials = true;

type loginProps = {
  idToken: string;
};
// login
const login = async (props: loginProps) => {
  const { idToken } = props;

  try {
    const res = await axios.post(
      `${getAuthEndpoint()}/login`,
      {
        idToken,
      }
    );

    return {
      data: res.data,
    };
  } catch (error) {
    const { data } = error?.response || {};

    return {
      error: data,
    };
  }
};

// logout
const logout = async () => {
  try {
    await axios.post(`${getAuthEndpoint()}/logout`);
  } catch (error) {}
};

const useAuthReturnValue = {
  login,
  logout,
};

export default useAuthReturnValue;
