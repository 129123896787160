// error codes and their respective user friendly error messages. Messaging unique to each application (operator, driver, customer)
export const ErrorMessages = {
  // default error codes
  MOOVS_INTERNAL_ERROR:
    "Oops, something went wrong. Please contact info@moovsapp.com if issue persists.",
  MOOVS_USER_INPUT_ERROR: "Please provide a valid value.",
  MOOVS_NOT_FOUND_ERROR:
    "Oops, something went wrong. Please contact info@moovsapp.com if issue persists.",
  MOOVS_AUTHENTICATION_ERROR:
    "Oops, something went wrong. Please contact info@moovsapp.com if issue persists.",
  MOOVS_FORBIDDEN_ERROR:
    "Oops, something went wrong. Please contact info@moovsapp.com if issue persists.",
  MOOVS_UNPROCESSABLE_ENTITY_ERROR:
    "Oops, something went wrong. Please contact info@moovsapp.com if issue persists.",
  MOOVS_UNREACHABLE_CASE_ERROR:
    "Oops, something went wrong. Please contact info@moovsapp.com if issue persists.", //(this one is predominately griddnet related at the moment)
  MOOVS_BAD_REQUEST_ERROR:
    "Oops, something went wrong. Please contact info@moovsapp.com if issue persists.",
  MOOVS_API_REQUEST_ERROR:
    "Oops, something went wrong. Please contact info@moovsapp.com if issue persists.",

  // auth
  MOOVS_NO_PENDING_INVITE:
    "Invite to join team is no longer valid. Please contact operator to send another invitation or",
  MOOVS_USER_EMAIL_IN_USE: "Email is already in use,",
  MOOVS_USER_INVITE_PENDING:
    "Member Invite Pending, check email to complete onboarding,",
  MOOVS_ONE_HOME_PERMISSION_REQUIRED:
    "Member must have at least 1 Home permission",
  MOOVS_EXISTING_NAME_SLUG_ERROR: "Existing URL. Please update to be unique.",
  MOOVS_NO_PERMISSION_TO_CONNECT_STRIPE_ACCOUNT:
    "Only members with an 'Owner' role have the permission to set up Moovs Payments.",
  MOOVS_CAN_NOT_GENERATE_NAME_SLUG_ERROR:
    "Could not generate unique URL with the provided company name.",

  // twilio error codes
  MOOVS_TWILIO_ERROR: "Please contact info@moovsapp.com if issue persists.",
  MOOVS_TWILIO_USER_CONVERSATION_LIMIT_EXCEEDED:
    "Conversation Limit Exceeded. Please contact info@moovsapp.com if issue persists.",
  MOOVS_TWILIO_INVALID_PHONE_NUMBER:
    "Invalid phone number. Please check that the recipient phone number is correct.",

  // stripe error codes
  MOOVS_STRIPE_AUTHENTICATION_REQUIRED:
    "Transaction requires authorization, please try again with authenticated card. Contact card issuer if problem persists.",
  MOOVS_STRIPE_APPROVE_WITH_ID:
    "The payment cannot be authorized, please try again. Contact card issuer if problem persists.",
  MOOVS_STRIPE_CALL_ISSUER:
    "An error has occured, please contact the card issuer.",
  MOOVS_STRIPE_CARD_NOT_SUPPORTED:
    "This card does not support this type of purchase. Contact card issuer.",
  MOOVS_STRIPE_CARD_VELOCITY_EXCEEDED:
    "The balance or credit limit for this card has been exceeded.",
  MOOVS_STRIPE_CURRENCY_NOT_SUPPORTED:
    "The card does not support the specified currency.",
  MOOVS_STRIPE_DO_NOT_TRY_AGAIN:
    "The card has been declined for an unknown reason. Please contact card issuer.",
  MOOVS_STRIPE_DUPLICATE_TRANSACTION:
    "A transaction with identical amount and credit card information was submitted very recently.",
  MOOVS_STRIPE_EXPIRED_CARD: "The card has expired.",
  MOOVS_STRIPE_FRAUDULENT:
    "The payment has been declined as we suspect it is fraudulent.", // operator-app specific messaging
  MOOVS_STRIPE_GENERIC_DECLINE:
    "The card has been declined for an unknown reason.",
  MOOVS_STRIPE_INCORRECT_NUMBER: "The card number is incorrect.",
  MOOVS_STRIPE_INCORRECT_CVC: "The CVC number is incorrect.",
  MOOVS_STRIPE_INCORRECT_PIN: "The PIN entered is incorrect.",
  MOOVS_STRIPE_INCORRECT_ZIP: "The ZIP/postal code is incorrect.",
  MOOVS_STRIPE_INSUFFICIENT_FUNDS:
    "The card has insufficient funds to complete the purchase.",
  MOOVS_STRIPE_INVALID_ACCOUNT:
    "The card, or account the card is connected to, is invalid.",
  MOOVS_STRIPE_INVALID_AMOUNT:
    "The payment amount is invalid, or exceeds the amount that is allowed.",
  MOOVS_STRIPE_INVALID_CVC: "The CVC number is incorrect.",
  MOOVS_STRIPE_INVALID_EXPIRY_MONTH: "The expiration month is invalid.",
  MOOVS_STRIPE_INVALID_EXPIRY_YEAR: "The expiration year is invalid",
  MOOVS_STRIPE_INVALID_NUMBER: "The card number is incorrect.	",
  MOOVS_STRIPE_INVALID_PIN: "The PIN entered is incorrect.",
  MOOVS_STRIPE_ISSUER_NOT_AVAILABLE:
    "The card issuer could not be reached, so the payment could not be authorized.",
  MOOVS_STRIPE_LOST_CARD:
    "The payment has been declined because the card is reported lost.",
  MOOVS_STRIPE_MERCHANT_BLACKLIST:
    "The card has been declined for an unknown reason", // just send generic decline message, stripe recommends not to report this decline to the customer
  MOOVS_STRIPE_NEW_ACCOUNT_INFORMATION_AVAILABLE:
    "The card, or account the card is connected to, is invalid. Please contact card issuer.",
  MOOVS_STRIPE_NO_ACTION_TAKEN:
    "The card has been declined for an unknown reason. Please contact card issuer.",
  MOOVS_STRIPE_NOT_PERMITTED:
    "The payment is not permitted. Please contact card issuer.",
  MOOVS_STRIPE_OFFLINE_PIN_REQUIRED:
    "The card has been declined as it requires a PIN.",
  MOOVS_STRIPE_ONLINE_OR_OFFLINE_PIN_REQUIRED:
    "The card has been declined as it requires a PIN.",
  MOOVS_STRIPE_PICKUP_CARD:
    "The card cannot be used to make this payment (it is possible it has been reported lost or stolen).",
  MOOVS_STRIPE_PIN_TRY_EXCEEDED:
    "The allowable number of PIN tries has been exceeded.",
  MOOVS_STRIPE_PROCESSING_ERROR: "An error occurred while processing the card.",
  MOOVS_STRIPE_REENTER_TRANSACTION:
    "The payment could not be processed by the issuer for an unknown reason.",
  MOOVS_STRIPE_RESTRICTED_CARD:
    "The card cannot be used to make this payment (it is possible it has been reported lost or stolen).",
  MOOVS_STRIPE_REVOCATION_OF_ALL_AUTHORIZATIONS:
    "The card has been declined for an unknown reason.",
  MOOVS_STRIPE_REVOCATION_OF_AUTHORIZATION:
    "The card has been declined for an unknown reason.",
  MOOVS_STRIPE_SECURITY_VIOLATION:
    "The card has been declined for an unknown reason.",
  MOOVS_STRIPE_SERVICE_NOT_ALLOWED:
    "The card has been declined for an unknown reason.",
  MOOVS_STRIPE_STOLEN_CARD: "The card has been declined for an unknown reason", // just send generic decline message, stripe recommends not to report this decline to the customer
  MOOVS_STRIPE_STOP_PAYMENT_ORDER:
    "The card has been declined for an unknown reason.",
  MOOVS_STRIPE_TESTMODE_DECLINE: "A Stripe test card number was used.",
  MOOVS_STRIPE_TRANSACTION_NOT_ALLOWED:
    "The card has been declined for an unknown reason.",
  MOOVS_STRIPE_TRY_AGAIN_LATER:
    "The card has been declined for an unknown reason.",
  MOOVS_STRIPE_WITHDRAWAL_COUNT_LIMIT_EXCEEDED:
    "The customer has exceeded the balance or credit limit available on their card.",

  // when we are unable to retrieve a declineCode from stripe, payment related codes will be:
  MOOVS_STRIPE_ACCOUNT_NOT_CONNECTED:
    "Please connect to Moovs merchant processor.",
  MOOVS_PAYMENT_INTENT_USER_INPUT_ERROR:
    "There was an issue with the payment. Please contact info@moovsapp.com if issue persists.",
  MOOVS_REFUND_USER_INPUT_ERROR:
    "There was an issue with the refund. Please contact info@moovsapp.com if issue persists.",
  MOOVS_PAYMENT_METHOD_INPUT_ERROR: "Please provide valid payment method.",
  MOOVS_GRATUITY_USER_INPUT_ERROR:
    "Please provide a gratuity amount that is less than the total amount.", //(when attempting to create or update transaction & gratuity amount input is more than the total amount input)
  MOOVS_PROMO_AMOUNT_USER_INPUT_ERROR:
    "Please provide a promo amount that is less than or equal to total amount.", // (when attempting to create or update transaction & promo amount input is more than the total amount input)
  MOOVS_PERCENTAGE_AMOUNT_USER_INPUT_ERROR:
    "Please provide a valid percentage amount.",
  MOOVS_DOLLAR_AMOUNT_USER_INPUT_ERROR: "Please provide a valid dollar amount.",
  MOOVS_PRICE_ZONE_ZIP_CODE_USER_INPUT_ERROR:
    "Please provide a valid ZIP/postal code.",

  // creating driver with existing phone number
  MOOVS_USER_INPUT_EXISTING_DRIVER_PHONE_NUMBER:
    "Existing driver has same phone number. All driver phone numbers must be unique.",

  // replacing a shuttle vehicle that does not have capacity for seats pre-booked
  MOOVS_USER_INPUT_SHUTTLE_VEHICLE_CAPACITY:
    "Unable to assign vehicle due to capacity. There are more booked seats than vehicle capacity.",

  // operator is required to have a minimal of 1 terms & condition to turn on consent setting
  MOOVS_REQUIRE_TERMS_CONDITION:
    "Must have at least one terms & condition to turn consent setting on",

  // review platform URL failed check
  MOOVS_USER_INPUT_REVIEW_SITE_ID:
    "Link error. Please double check your corporation ID",

  // onboarding
  MOOVS_INVALID_MOBILE_NUMBER: "Phone number must be for a valid mobile number",
  MOOVS_USER_INPUT_LINKED_PASSENGER_SAME_CONTACT:
    "You cannot link a contact to itself",
  MOOVS_PAYMENT_METHOD_ALREADY_HAS_LINKED_PASSENGER:
    "This card is already linked to a passenger",
  MOOVS_CONTACT_NOT_LINKED_TO_PASSENGER:
    "Forbidden! This passenger is not linked to this contact",

  // when all trips are removed by operator and operator tries to send email reservation
  MOOVS_CAN_NOT_SEND_EMAIL_DUE_TO_NO_TRIPS_ERROR:
    "Can not send reservation email because there are currently no trips associated with this reservation",
  MOOVS_INSTANT_PAYOUT_LIMIT_REACHED: "Instant payout limit reached",
};
