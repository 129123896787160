import { ApolloQueryResult, useMutation } from "@apollo/client";
import { useCallback, useEffect, useRef } from "react";

import { UPDATE_VEHICLE_SETTINGS_MUTATION } from "globals/graphql";
import { useSnackbar } from "globals/hooks";
import { useDebounce } from "use-debounce";
import { VehiclePricingError } from "../types";
import { FarmAffiliateVehicle, Vehicle } from "types";

type UpdateVehicleSettingsMutationProps = {
  vehicle: Partial<Vehicle | FarmAffiliateVehicle>;
  vehiclePricingErrors?: VehiclePricingError;
  vehicleSettingsInput: {
    conflictBlockQuote: boolean;
    conflictBlockReservation: boolean;
    weekends: [];
  };
  setVehicleSettingsInput: (e: any) => void;
  setSaveIndicatorState: (e: any) => void;
  setPublishedIndicatorState: (e: any) => void;
  vehicleRefetch?: () => Promise<ApolloQueryResult<any>>;
};

function useUpdateVehicleSettingsMutation(
  props: UpdateVehicleSettingsMutationProps
) {
  // props
  const {
    vehicle,
    vehiclePricingErrors,
    vehicleSettingsInput,
    setVehicleSettingsInput,
    setSaveIndicatorState,
    setPublishedIndicatorState,
    vehicleRefetch,
  } = props;

  // state
  const [debouncedVehicleSettings] = useDebounce(vehicleSettingsInput, 500);
  const flag = useRef(false);
  const firstRender = useRef(true);

  // hooks
  const snackbar = useSnackbar();

  const [updateVehicleSettings] = useMutation(
    UPDATE_VEHICLE_SETTINGS_MUTATION,
    {
      onCompleted() {
        vehicleRefetch && vehicleRefetch();
        setSaveIndicatorState && setSaveIndicatorState("saved");
        setPublishedIndicatorState && setPublishedIndicatorState("publishing");
        setTimeout(() => {
          setPublishedIndicatorState && setPublishedIndicatorState("default");
        }, 3000);
      },
      onError(error) {
        setSaveIndicatorState && setSaveIndicatorState("error");
        snackbar.error("Error updating vehicle");

        flag.current = false;
      },
    }
  );

  const handleDebouncedVehicleSettingsUpdate = useCallback(() => {
    setSaveIndicatorState && setSaveIndicatorState("loading");

    if (
      vehiclePricingErrors?.weekendHourlyCost ||
      vehiclePricingErrors?.weekendMinMinutes ||
      vehiclePricingErrors?.settings
    ) {
      setSaveIndicatorState && setSaveIndicatorState("error");
      return;
    }

    for (const error in vehiclePricingErrors) {
      if (vehiclePricingErrors[error].length > 0) {
        setSaveIndicatorState && setSaveIndicatorState("error");
        return;
      }
    }

    updateVehicleSettings({
      variables: {
        input: {
          vehicleId: vehicle.id,
          weekends: vehicleSettingsInput.weekends || [],
          conflictBlockQuote: vehicleSettingsInput.conflictBlockQuote,
          conflictBlockReservation:
            vehicleSettingsInput.conflictBlockReservation,
        },
      },
    });
  }, [
    vehicle,
    updateVehicleSettings,
    setSaveIndicatorState,
    vehiclePricingErrors,
    vehicleSettingsInput,
  ]);

  // USE EFFECTS
  // configures weekends to queried vehicle on first load
  useEffect(() => {
    if (firstRender.current) {
      setVehicleSettingsInput(vehicle.settings);
      firstRender.current = false;
    }
  }, [vehicle.settings, setVehicleSettingsInput]);

  // updates based on debounced states
  useEffect(() => {
    if (!debouncedVehicleSettings) {
      return;
    }

    if (!flag.current && firstRender) {
      flag.current = true;
    } else {
      handleDebouncedVehicleSettingsUpdate();
    }
  }, [debouncedVehicleSettings, handleDebouncedVehicleSettingsUpdate, flag]);

  return;
}

export { useUpdateVehicleSettingsMutation };
