import React, { useState } from "react";
import { useMutation } from "@apollo/client";

import {
  Box,
  Select,
  Typography,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";

import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { useOperator, useScreenSize, useSnackbar } from "globals/hooks";
import { granite, grayDark, black } from "design-system/colors";
import { ChevronDownIcon, CheckIcon } from "design-system/icons";
import { UPDATE_BOOKING_CUTOFF_TIME } from "globals/graphql";

const CUTOFF_TIME_VALUES = [...Array(24).keys()]; // 0-23

const CUTOFF_TIME_TYPES = [
  { value: "DAYS", label: "Days" },
  { value: "HOURS", label: "Hours" },
];

type ReservationCutoffProps = {
  setSaveIndicatorState: (
    savedState: "default" | "loading" | "saved" | "error"
  ) => void;
};

function ReservationCutoff(props: ReservationCutoffProps) {
  const { setSaveIndicatorState } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const snackbar = useSnackbar();
  const operator = useOperator();

  const { bookingCutoffTimeType, bookingCutoffTimeValue } = operator;

  // state
  const [bookingCutoffTime, setBookingCutoffTime] = useState({
    bookingCutoffTimeValue,
    bookingCutoffTimeType,
  });

  // mutations
  const [updateBookingCutoffTime] = useMutation(UPDATE_BOOKING_CUTOFF_TIME, {
    onCompleted() {
      setSaveIndicatorState("saved");
    },
    onError(error) {
      setSaveIndicatorState("error");
      const errorMessage =
        getErrorMessage(error) || "Error updating base rate automation cutoff.";

      snackbar.error(errorMessage);
    },
  });

  // event handlers
  const handleChange = (event) => {
    setSaveIndicatorState("loading");

    setBookingCutoffTime({
      ...bookingCutoffTime,
      [event.target.name]: event.target.value,
    });

    updateBookingCutoffTime({
      variables: {
        input: {
          [event.target.name]: event.target.value,
        },
      },
    });
  };

  const isPlural =
    bookingCutoffTime.bookingCutoffTimeValue === 0 ||
    bookingCutoffTime.bookingCutoffTimeValue > 1;

  const description = `No reservations created within ${
    bookingCutoffTime.bookingCutoffTimeValue
  } ${
    isPlural
      ? bookingCutoffTime.bookingCutoffTimeType.toLowerCase()
      : bookingCutoffTime.bookingCutoffTimeType.toLowerCase().slice(0, -1)
  } of booking time`;

  return (
    <>
      <Typography variant="h3" mb={2}>
        Reservation Cutoff
      </Typography>
      <Box
        display="flex"
        flexDirection={isMobileView ? "column" : "row"}
        {...(!isMobileView && { alignItems: "center" })}
      >
        <Box flex="1" {...(isMobileView && { mb: 1.5 })}>
          <Typography variant="body2">Reservation cutoff period</Typography>
          <Typography variant="caption" style={{ color: granite }}>
            {description}
          </Typography>
        </Box>

        <Box display="flex" flex="1" justifyContent="flex-end">
          <Box
            maxWidth={isMobileView ? "100%" : 85}
            display="flex"
            flex="1"
            mr={1}
          >
            {/* value select (0-23) */}
            <Select
              value={bookingCutoffTime.bookingCutoffTimeValue}
              renderValue={() => bookingCutoffTime.bookingCutoffTimeValue}
              fullWidth
              variant="outlined"
              name="bookingCutoffTimeValue"
              onChange={handleChange}
              IconComponent={() => (
                <Box
                  position="relative"
                  top="5px"
                  right="20px"
                  sx={[
                    {
                      pointerEvents: "none",
                    },
                  ]}
                >
                  <ChevronDownIcon color={grayDark} size="small" />
                </Box>
              )}
            >
              {CUTOFF_TIME_VALUES.map((value) => (
                <MenuItem value={value} key={value}>
                  <Box mr={2}>
                    <ListItemText primary={value} />
                  </Box>
                  {value === bookingCutoffTime.bookingCutoffTimeValue && (
                    <ListItemIcon>
                      <CheckIcon color={black} size="small" />
                    </ListItemIcon>
                  )}
                </MenuItem>
              ))}
            </Select>
          </Box>

          {/* type select (days/hours) */}
          <Box maxWidth={isMobileView ? "100%" : 121} display="flex" flex="1">
            <Select
              value={bookingCutoffTime.bookingCutoffTimeType}
              renderValue={() => {
                if (bookingCutoffTime.bookingCutoffTimeType === "HOURS") {
                  return `Hour${isPlural ? "s" : ""}`;
                } else {
                  return `Day${isPlural ? "s" : ""}`;
                }
              }}
              fullWidth
              variant="outlined"
              name="bookingCutoffTimeType"
              onChange={handleChange}
              IconComponent={() => (
                <Box
                  position="relative"
                  top="5px"
                  right="20px"
                  sx={[
                    {
                      pointerEvents: "none",
                    },
                  ]}
                >
                  <ChevronDownIcon color={grayDark} size="small" />
                </Box>
              )}
            >
              {CUTOFF_TIME_TYPES.map((type) => {
                const { value, label } = type;

                return (
                  <MenuItem value={value} key={value}>
                    <Box mr={2}>
                      <ListItemText
                        primary={isPlural ? label : label.slice(0, -1)}
                      />
                    </Box>
                    {value === bookingCutoffTime.bookingCutoffTimeType && (
                      <ListItemIcon>
                        <CheckIcon color={black} size="small" />
                      </ListItemIcon>
                    )}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ReservationCutoff;
