import React from "react";

import { Box, Switch, Typography } from "@mui/material";

import { grayDark, black } from "design-system/colors";
import { useScreenSize } from "globals/hooks";
import { CreateVehicle } from "components/vehicles/CreateVehicleDrawer/types";
import { Vehicle } from "types";

type BookingToolSettingSectionProps = {
  handleBookingToolSettingsToggle: (e: any) => void;
  mobileImage: string;
  desktopImage: string;
  title: string;
  subtext: string;
  name: string;
  vehicle: CreateVehicle | Partial<Vehicle>;
};

function BookingToolSettingSection(props: BookingToolSettingSectionProps) {
  const {
    handleBookingToolSettingsToggle,
    mobileImage,
    desktopImage,
    title,
    subtext,
    vehicle,
    name,
  } = props;

  // hooks
  const { isSmallTabletView } = useScreenSize();

  return (
    <Box mb={3}>
      <Box display="flex" justifyContent="space-between">
        <Box maxWidth={isSmallTabletView ? "100%" : "80%"}>
          <Typography
            variant="body2"
            mb={0.5}
            sx={{
              color: black,
            }}
          >
            {title}
          </Typography>
          <Typography sx={{ fontSize: "12px", color: grayDark }}>
            {subtext}
          </Typography>
        </Box>
        <Box display="flex">
          <Switch
            color="primary"
            checked={vehicle[name]}
            onChange={handleBookingToolSettingsToggle}
            name={name}
          />
        </Box>
      </Box>
      <Box mt={2}>
        <img
          src={isSmallTabletView ? mobileImage : desktopImage}
          alt="booking-tool-setting"
          width="100%"
        />
      </Box>
    </Box>
  );
}

export default BookingToolSettingSection;
