import React, { useState } from "react";
import { ApolloQueryResult } from "@apollo/client";

import { Box, Typography } from "@mui/material";

import VehicleFormSection from "components/vehicles/VehicleFormSection";
import DisplayBookingToolRequestMobile from "design-system/images/DisplayBookingToolRequestMobile.png";
import DisplayBookingToolRequestDesktop from "design-system/images/DisplayBookingToolRequestDesktop.png";
import BookingToolBRAMobile from "design-system/images/BookingToolBRAMobile.png";
import BookingToolBRADesktop from "design-system/images/BookingToolBRADesktop.png";
import BookingToolBlockQuoteDesktop from "design-system/images/BookingToolBlockQuoteDesktop.png";
import BookingToolBlockQuoteMobile from "design-system/images/BookingToolBlockQuoteMobile.png";
import BookingToolBlockReservationDesktop from "design-system/images/BookingToolBlockReservationDesktop.png";
import BookingToolBlockReservationMobile from "design-system/images/BookingToolBlockReservationMobile.png";
import { useAnalytics, useScreenSize } from "globals/hooks";
import { Vehicle } from "types";
import {
  BookingToolSettingSection,
  BookingToolSettingSectionWithPlanUpgrade,
} from "components/vehicles/CreateVehicleDrawer/components/VehicleTabViews/BookingToolSettings/components";
import { grayDark } from "design-system/colors";
import { useUpdateVehicleSettingsMutation } from "components/vehicles/hooks/useUpdateVehicleSettingsMutation";

type BookingToolSettingsProps = {
  vehicle: Partial<Vehicle>;
  setVehicle: React.Dispatch<React.SetStateAction<Partial<Vehicle>>>;
  setSaveIndicatorState?: (
    value: "default" | "loading" | "saved" | "error"
  ) => void;
  setPublishedIndicatorState?: (
    value: "default" | "publishing" | "published" | "error"
  ) => void;
  vehicleRefetch: () => Promise<ApolloQueryResult<any>>;
};

function BookingToolSettings(props: BookingToolSettingsProps) {
  const {
    vehicle,
    setVehicle,
    setSaveIndicatorState,
    setPublishedIndicatorState,
    vehicleRefetch,
  } = props;

  // state
  const [vehicleSettingsInput, setVehicleSettingsInput] = useState(null);

  // hooks
  const { track } = useAnalytics();
  const { isSmallTabletView } = useScreenSize();
  useUpdateVehicleSettingsMutation({
    setSaveIndicatorState,
    setPublishedIndicatorState,
    vehicle,
    setVehicleSettingsInput,
    vehicleSettingsInput,
    vehicleRefetch,
  });

  // event handler
  const handleBookingToolSettingsToggle = (e) => {
    let { checked, name } = e.target;

    if (name === "enableBaseRateAutomationBookingTool") {
      track("vehicleBookingTool_braUpdated", {
        toggleState: checked ? "On" : "Off",
      });
    }

    setVehicle({ ...vehicle, [name]: checked });
  };

  const handleVehicleSettingsToggle = (e) => {
    const { name, checked } = e.target;

    setVehicle({
      ...vehicle,
      settings: {
        ...vehicle.settings,
        [name]: checked,
      },
    });

    setVehicleSettingsInput({
      ...vehicle.settings,
      [name]: checked,
    });

    if (name === "conflictBlockQuote") {
      checked
        ? track("vehicleBookingTool_blockQuoteEnabled")
        : track("vehicleBookingTool_blockQuoteDisabled");
    } else if (name === "conflictBlockReservation") {
      checked
        ? track("vehicleBookingTool_blockReservationEnabled")
        : track("vehicleBookingTool_blockReservationDisabled");
    }
  };

  return (
    <Box mt={4}>
      <VehicleFormSection title="Customer Portal Settings">
        {/* Display on Customer Portal */}
        <BookingToolSettingSection
          vehicle={vehicle}
          handleBookingToolSettingsToggle={handleBookingToolSettingsToggle}
          mobileImage={DisplayBookingToolRequestMobile}
          desktopImage={DisplayBookingToolRequestDesktop}
          name="available"
          title="Display on Customer Portal"
          subtext="When this is on, vehicle will be displayed on customer portal for customers to request"
        />

        {/* Enable BRA */}
        <BookingToolSettingSection
          vehicle={vehicle}
          handleBookingToolSettingsToggle={handleBookingToolSettingsToggle}
          mobileImage={BookingToolBRAMobile}
          desktopImage={BookingToolBRADesktop}
          name="enableBaseRateAutomationBookingTool"
          title="Enable Base Rate Automation on Customer Portal"
          subtext="When this is on, price will be displayed and customers can book vehicle without requesting a quote"
        />

        {/* Block Quote */}
        <BookingToolSettingSectionWithPlanUpgrade
          value={vehicleSettingsInput?.conflictBlockQuote}
          handleVehicleSettingsToggle={handleVehicleSettingsToggle}
          mobileImage={BookingToolBlockQuoteMobile}
          desktopImage={BookingToolBlockQuoteDesktop}
          name="conflictBlockQuote"
          imgWidth="100%"
          title="Block quote requests when vehicle is unavailable"
          subtext={
            <Typography
              sx={{
                fontSize: "12px",
                color: grayDark,
              }}
            >
              When this is on, customer will not be able to{" "}
              <Typography fontWeight="600" fontSize="12px" display="inline">
                submit a quote request
              </Typography>{" "}
              if there is a trip conflict. Customer will be instructed to choose
              another vehicle, update pickup time or contact you directly.
            </Typography>
          }
        />

        {/* Block Reservation */}
        <BookingToolSettingSectionWithPlanUpgrade
          value={vehicleSettingsInput?.conflictBlockReservation}
          handleVehicleSettingsToggle={handleVehicleSettingsToggle}
          mobileImage={BookingToolBlockReservationMobile}
          desktopImage={BookingToolBlockReservationDesktop}
          name="conflictBlockReservation"
          title="Block reservations when vehicle is unavailable"
          imgWidth={isSmallTabletView ? "80%" : "60%"}
          subtext={
            <Typography
              sx={{
                fontSize: "12px",
                color: grayDark,
              }}
            >
              When this is on, customer will not be able to{" "}
              <Typography fontWeight="600" fontSize="12px" display="inline">
                reserve
              </Typography>{" "}
              if there is a trip conflict. Customer will be instructed to choose
              another vehicle, update pickup time or contact you directly.
            </Typography>
          }
        />
      </VehicleFormSection>
    </Box>
  );
}

export default BookingToolSettings;
