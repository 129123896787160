export const VehiclePricingInitialErrors = {
  minimumTotalBaseRate: "",
  deadheadRatePerMile: "",
  tripRatePerMile: "",
  weekdayHourlyCost: "",
  weekdayMinMinutes: "",
  weekendHourlyCost: "",
  weekendMinMinutes: "",
  settings: "",
};
