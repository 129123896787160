import { Cell } from "../../utils/types";

const row1: Cell[] = [
  { column: 1, typographyText: "Credit Card Rate", typographyVariant: "h6" },
  {
    column: 2,
    typographyText: "4% + 30 cents transaction",
    typographyVariant: "caption",
  },
  {
    column: 3,
    typographyText: "3.4% + 30 cents transaction",
    typographyVariant: "caption",
  },
  {
    column: 4,
    typographyText: "3% + 30 cents transaction",
    typographyVariant: "caption",
  },
  {
    column: 5,
    typographyText: "Get in touch",
    typographyVariant: "caption",
  },
];

const row2: Cell[] = [
  { column: 1, typographyText: "Members", typographyVariant: "h6" },
  { column: 2, typographyText: "3", typographyVariant: "caption" },
  { column: 3, typographyText: "3", typographyVariant: "caption" },
  { column: 4, typographyText: "5", typographyVariant: "caption" },
  { column: 5, typographyText: "Unlimited", typographyVariant: "caption" },
];

const row3: Cell[] = [
  { column: 1, typographyText: "Setup Fee", typographyVariant: "h6" },
  { column: 2, typographyText: "$0", typographyVariant: "caption" },
  { column: 3, typographyText: "$299", typographyVariant: "caption" },
  { column: 4, typographyText: "$299", typographyVariant: "caption" },
  { column: 5, typographyText: "Get in touch", typographyVariant: "caption" },
];

const row4: Cell[] = [
  {
    column: 1,
    typographyText: "Ride Amounts",
    typographyVariant: "h6",
  },
  { column: 2, typographyText: "Up to 10/mo", typographyVariant: "caption" },
  { column: 3, typographyText: "Up to 150/mo", typographyVariant: "caption" },
  { column: 4, typographyText: "Up to 250/mo", typographyVariant: "caption" },
  { column: 5, typographyText: "Get in touch", typographyVariant: "caption" },
];

const row5: Cell[] = [
  { column: 1, typographyText: "Vehicles", typographyVariant: "h6" },
  { column: 2, typographyText: "10", typographyVariant: "caption" },
  { column: 3, typographyText: "Unlimited", typographyVariant: "caption" },
  { column: 4, typographyText: "Unlimited", typographyVariant: "caption" },
  { column: 5, typographyText: "Unlimited", typographyVariant: "caption" },
];

const row6: Cell[] = [
  { column: 1, typographyText: "Drivers", typographyVariant: "h6" },
  { column: 2, typographyText: "10", typographyVariant: "caption" },
  { column: 3, typographyText: "Unlimited", typographyVariant: "caption" },
  { column: 4, typographyText: "Unlimited", typographyVariant: "caption" },
  { column: 5, typographyText: "Unlimited", typographyVariant: "caption" },
];

const row7: Cell[] = [
  {
    column: 1,
    typographyText: "Passenger App iOS/Android",
    typographyVariant: "h6",
  },
  {
    column: 2,
    typographyText: "$499/mo with $1000 Setup Fee",
    typographyVariant: "caption",
  },
  {
    column: 3,
    typographyText: "$499/mo with $1000 Setup Fee",
    typographyVariant: "caption",
  },
  {
    column: 4,
    typographyText: "$499/mo with $1000 Setup Fee",
    typographyVariant: "caption",
  },
  {
    column: 5,
    typographyText: "$499/mo with $1000 Setup Fee",
    typographyVariant: "caption",
  },
];

const row8: Cell[] = [
  { column: 1, typographyText: "Shuttle App", typographyVariant: "h6" },
  {
    column: 2,
    typographyText: "$299 per Vehicle",
    typographyVariant: "caption",
  },
  {
    column: 3,
    typographyText: "$299 per Vehicle",
    typographyVariant: "caption",
  },
  {
    column: 4,
    typographyText: "$299 per Vehicle",
    typographyVariant: "caption",
  },
  {
    column: 5,
    typographyText: "$299 per Vehicle",
    typographyVariant: "caption",
  },
];

const row9: Cell[] = [
  { column: 1, typographyText: "Promo Codes", typographyVariant: "h6" },
  { column: 2, typographyText: "1", typographyVariant: "caption" },
  { column: 3, typographyText: "1", typographyVariant: "caption" },
  { column: 4, typographyText: "Unlimited", typographyVariant: "caption" },
  { column: 5, typographyText: "Unlimited", typographyVariant: "caption" },
];

const chartData = [row1, row2, row3, row4, row5, row6, row7, row8, row9];

export default chartData;
