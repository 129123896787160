/**
 * TODO: This file is getting bloated - separate the header, body, and footer
 *   into their own components and move the media logic into separate
 *   encapsulating hooks.
 * */
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import clone from "lodash/clone";
import isEqual from "lodash/isEqual";

import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";

import {
  grayMedium,
  granite,
  white,
  errorRed,
  moovsBlue,
} from "../../../design-system/colors";
import {
  ArrowLeftIcon,
  AttachIcon,
  WarningTriangleIcon,
} from "../../../design-system/icons";
import MessageBubble from "./MessageBubble";
import MediaPreviewList from "../media/MediaPreviewList";
import {
  useAnalytics,
  useOperator,
  useScreenSize,
  useSnackbar,
} from "../../../globals/hooks";
import { ConversationType } from "../../../globals/hooks/useChat/Provider";
import { getAffiliateId, MediaFileType, validMediaTypes } from "../utils";
import AttachMediaPopup from "../media/AttachMediaPopup";
import AddMoovsMediaDialog from "../media/AddMediaFromMoovs/AddMediaFromMoovsDialog";
import { VehiclePhoto, PlanVariant } from "types";
import { CHAT_PANEL_WIDTH } from "theme";
import UpgradePlanDialog from "components/UpgradePlanDialog";

const initialAttachmentErrors = {
  hasFileExceedingMaxSize: false,
  hasInvalidMediaType: false,
  hasOverTenAttachments: false,
};

const attachmentErrorMessages = {
  hasFileExceedingMaxSize: {
    text: "The file is too large.",
    subtext: "The maximum file size is 5 MB",
  },
  hasInvalidMediaType: {
    text: "The file type is unsupported.",
    subtext: "Please upload a JPG, JPEG, PNG, MP4, or PDF File.",
  },
  hasOverTenAttachments: {
    text: "",
    subtext: "The maximum number of attachments per message is 10",
  },
};

type ConversationProps = {
  conversation: ConversationType;
  onBackClick: () => void;
};

function Conversation(props: ConversationProps) {
  const { conversation, onBackClick } = props;
  const {
    proxy: conversationProxy,
    messages,
    setUnreadMessagesCount,
    participant,
  } = conversation;

  // hooks
  const snackbar = useSnackbar();
  const operator = useOperator();
  const { isMobileView } = useScreenSize();
  const { track } = useAnalytics();
  const history = useHistory();

  const affiliateId = getAffiliateId(operator?.id);

  // state
  const [newMessage, setNewMessage] = useState("");
  const [attachments, setAttachment] = useState<MediaFileType[]>([]);
  const [attachmentErrors, setAttachmentErrors] = useState(
    initialAttachmentErrors
  );
  const [isSendingMessages, setIsSendingMessage] = useState(false);
  const [shouldRenderMessages, setShouldRenderMessages] = useState(false);
  const [attachMenuOpen, setAttachmentMenuOpen] = useState(false);
  const [addMoovsMediaDialogOpen, setAddMoovsMediaDialogOpen] = useState(false);
  const [upgradePlanDialogOpen, setUpgradePlanDialogOpen] = useState(false);

  // refs
  const bottomOfListRef = useRef(null);
  const attachMenuAnchorRef = useRef(null);

  // event handlers
  const handleMessageChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handleMessageSend = async (event) => {
    event.preventDefault();

    if (operator?.plan === PlanVariant.Free) {
      setUpgradePlanDialogOpen(true);
      return;
    }

    track("chatConversation_sent");
    const message = newMessage;

    if (!!message.length) {
      setNewMessage("");
      await conversationProxy.sendMessage(message);
    }

    if (!!attachments.length) {
      setIsSendingMessage(true);
      // waits to send next file after first file is finished sending
      for (let i = 0; i < attachments.length; i++) {
        // TODO: Consider adding a manual setTimeout delay here, so attachment has a better chance of being received in the correct order.
        const formData = new FormData();
        formData.set("file", attachments[i]);
        await conversationProxy.sendMessage(formData);
      }

      setIsSendingMessage(false);
      setAttachment([]);
    }

    await conversationProxy.setAllMessagesRead();
  };

  // submits on enter, shift enter for new line
  const handleKeyDown = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      handleMessageSend(event);
    }
  };

  // Future TODO: Move all attachment functions into a separate, encapsulating hook
  const handleAttachmentsFromFileSystem = async (event) => {
    const files = event.target.files;
    const newAttachments: MediaFileType[] = Array.from(files);

    const allAttachments = attachments.concat(newAttachments);

    checkForAttachmentErrors(allAttachments);
    setAttachment(allAttachments);
    setAttachmentMenuOpen(false);

    // resets the file input
    event.target.value = "";
  };

  const handleAttachmentsFromMoovs = async (photos: VehiclePhoto[]) => {
    try {
      const newAttachments = await Promise.all(
        photos.map(async (photo) => {
          const imageResponse = await fetch(photo.url, { method: "GET" });
          const blob = await imageResponse.blob();
          return new File([blob], photo.id, {
            type: blob.type || "image/jpeg",
          });
        })
      );
      const allAttachments = attachments.concat(newAttachments);

      checkForAttachmentErrors(allAttachments);
      setAttachment(allAttachments);
    } catch (err) {
      snackbar.error("Could not attach file.");
    }
  };

  const handleRemoveAttachment = (mediaIndex: number) => {
    const shallowAttachments = clone(attachments);
    shallowAttachments.splice(mediaIndex, 1);

    checkForAttachmentErrors(shallowAttachments);
    setAttachment(shallowAttachments);
  };

  const checkForAttachmentErrors = (attachmentList) => {
    const newAttachmentErrors = clone(initialAttachmentErrors);

    attachmentList.forEach((attachment) => {
      if (attachment.size > 5 * 1000000) {
        attachment.isInvalid = true;
        newAttachmentErrors.hasFileExceedingMaxSize = true;
      }

      if (!validMediaTypes.some((mediaType) => mediaType === attachment.type)) {
        attachment.isInvalid = true;
        newAttachmentErrors.hasInvalidMediaType = true;
      }
    });

    if (attachmentList.length > 10) {
      newAttachmentErrors.hasOverTenAttachments = true;
    }

    const errorsList = Object.entries(newAttachmentErrors);

    if (!isEqual(newAttachmentErrors, attachmentErrors)) {
      errorsList.forEach(([error, isError]) => {
        if (isError) {
          const { text, subtext } = attachmentErrorMessages[error];

          snackbar.warning("", {
            isSimple: true,
            icon: <WarningTriangleIcon color={errorRed} />,
            alertBodyComponent: (
              <Box>
                {text && (
                  <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    {text}
                  </Typography>
                )}
                {subtext && <Typography variant="body2">{subtext}</Typography>}
              </Box>
            ),
          });
        }
      });
    }

    setAttachmentErrors(newAttachmentErrors);
  };

  const handleUpgradePlanClick = () => {
    track("chatSend_upgradePlan");
    history.push("/settings/billing/plans");
  };

  // sets messages as read when page opens or new messages are added
  // todo: consider moving this into useChat hook
  useEffect(() => {
    conversationProxy.setAllMessagesRead();
    setUnreadMessagesCount(0);
  }, [conversationProxy, messages, setUnreadMessagesCount]);

  // scroll to bottom of page on mount
  useEffect(() => {
    // somewhat hacky way to make sure it scrolls to bottom on safari iOS
    setTimeout(() => bottomOfListRef?.current?.scrollIntoView(), 0);
    setTimeout(() => bottomOfListRef?.current?.scrollIntoView(), 300);
  }, []);

  // smooth scroll when messages are added
  useEffect(() => {
    setTimeout(
      () => bottomOfListRef?.current?.scrollIntoView({ behavior: "smooth" }),
      // arbirary time to wait for message to load in fully
      300
    );
  }, [messages]);

  // delay rendering page so messages load before page is visible
  useEffect(() => {
    setTimeout(() => {
      setShouldRenderMessages(true);
    }, 600);
  }, []);

  const sendIsDisabled =
    (!newMessage.length && !attachments.length) ||
    Object.values(attachmentErrors).some((error) => !!error) ||
    isSendingMessages;

  return (
    <>
      {/* header */}
      <Box
        display="flex"
        flexShrink={0}
        width="100%"
        alignItems="center"
        zIndex={10}
        bgcolor={white}
        borderBottom={`1px solid ${grayMedium}`}
        boxShadow="0px 0px 30px rgba(30, 30, 30, 0.1)"
        height={isMobileView ? "60px" : "78px"}
      >
        <IconButton onClick={onBackClick} size="large">
          <ArrowLeftIcon color={granite} />
        </IconButton>
        <Box display="flex" flexDirection="column">
          <Typography
            variant={isMobileView ? "subtitle2" : "button"}
            style={{ fontWeight: 700 }}
          >
            {participant.name || participant.mobilePhone}
          </Typography>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box mr={1}>
              <Typography variant="caption">
                {participant.mobilePhone}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* messages */}
      <Box px={1.5} overflow="auto" height="100%">
        <Box
          display="flex"
          flex={1}
          flexDirection="column"
          pt={2}
          {...(!shouldRenderMessages && { visibility: "hidden" })}
        >
          {messages?.length ? (
            messages.map((message, index) => {
              return (
                <MessageBubble
                  key={index}
                  isLastMessage={index === messages.length - 1}
                  direction={
                    message.author === affiliateId ? "outgoing" : "incoming"
                  }
                  message={message}
                  participant={
                    message.author === affiliateId
                      ? {
                          name: operator?.name,
                        }
                      : participant
                  }
                />
              );
            })
          ) : (
            <Box height="100vh">{/* temp spacer */}</Box>
          )}
          <div ref={bottomOfListRef} />
        </Box>
      </Box>
      {/* footer */}
      <Box
        display="flex"
        flexDirection="column"
        p={1}
        width={isMobileView ? "100vw" : CHAT_PANEL_WIDTH}
        zIndex={10}
        bgcolor={white}
        borderTop={`1px solid ${grayMedium}`}
        boxShadow="0px 0px 30px rgba(30, 30, 30, 0.1)"
      >
        <TextField
          variant="outlined"
          multiline
          placeholder="Type your message here..."
          value={newMessage}
          onChange={handleMessageChange}
          onKeyDown={handleKeyDown}
        />
        {!!attachments.length && (
          <Box mt={2}>
            <MediaPreviewList
              attachments={attachments}
              onRemoveAttachment={handleRemoveAttachment}
            />
          </Box>
        )}

        <Box
          mt={1}
          display="flex"
          flex={1}
          justifyContent="center"
          alignItems="center"
        >
          <>
            <Button
              ref={attachMenuAnchorRef}
              component="span"
              style={{ marginRight: "4px" }}
              onClick={() => setAttachmentMenuOpen(true)}
            >
              <AttachIcon color={moovsBlue} />
            </Button>
          </>

          <AttachMediaPopup
            attachMenuOpen={attachMenuOpen}
            ref={attachMenuAnchorRef}
            setAttachmentMenuOpen={setAttachmentMenuOpen}
            setAddMoovsMediaDialogOpen={setAddMoovsMediaDialogOpen}
            onAttachFromFileSystem={handleAttachmentsFromFileSystem}
          />

          {isSendingMessages ? (
            <Box
              flex="1"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress size={24} />
            </Box>
          ) : (
            <Button
              color="primary"
              disabled={sendIsDisabled}
              fullWidth
              variant="contained"
              onClick={handleMessageSend}
            >
              Send
            </Button>
          )}
        </Box>
      </Box>
      <AddMoovsMediaDialog
        open={addMoovsMediaDialogOpen}
        onClose={() => setAddMoovsMediaDialogOpen(false)}
        onAttachMediaFromMoovs={handleAttachmentsFromMoovs}
      />
      <UpgradePlanDialog
        open={upgradePlanDialogOpen}
        onUpgrade={handleUpgradePlanClick}
        onClose={() => setUpgradePlanDialogOpen(false)}
        body={
          <Typography variant="body2">
            You must upgrade your plan in order to{" "}
            <strong>send free text messages</strong>.
          </Typography>
        }
      />
    </>
  );
}

export default Conversation;
